import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Slide,
  Button,
  TextareaAutosize,
  DialogContentText,
  FormControlLabel,
} from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { FormErrors } from "components/Login/FormErrors";
import "react-tabs/style/react-tabs.css";
import InfiniteScroll from "react-infinite-scroller";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import DateFnsUtils from "@date-io/date-fns";
import { apiPath } from "api";
import { generalAuthTokenHeader } from "__helpers/auth-header";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import { Scrollbars } from "react-custom-scrollbars";
import { findProviderService } from "__helpers/util";
import { userService } from "_services/user.service";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import PrintIcon from '@material-ui/icons/Print';
import LanguageIcon from '@material-ui/icons/Language';
import {
  IconButton,
  Link as MaterialLink,
  LinearProgress,
} from "@material-ui/core";

import notesArrow from "assets/img/icons/notes-arrow.svg";
import mapMarker from "assets/img/icons/map marker.svg";
import infoIcon from "assets/img/icons/informationIcon-w.svg";
import enMsg from "__helpers/locale/en/en";
import PatientCriteria from "views/dpViews/SinglePatientDetail/PatientCriteria";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxLoad } from "js/actions/index";
import {
  REFERRAL_TYPE,
  MAX_INPUT_LENGTH,
  MAX_INPUT_LENGTH_LONG,
  MULTIPLE_PATIENT_LIST,
  SELECTED_PATIENT_LIST,
  PATIENT_LIST,
} from "__helpers/constants";
import { basePath, dpBaseRoutes } from "base-routes";
import PatientNotes from "views/dpViews/SinglePatientDetail/PatientNotes";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import { IMPORT_PATIENT_ORDERBY } from "__helpers/constants";
import { TRASPORT_NEEDS, TRASPORT_PAYOR } from "__helpers/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AutoComplete from "./AutoComplete";
import { USER_INFO } from "__helpers/constants";
import "./style.css";
import FacilityIndex from "views/Facility";
import { localDateFormat } from "__helpers/util";



function mapDispatchToProps(dispatch) {
  return {
    reduxLoad: (reduxLoadVal) => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = (state) => {
  return {
    userInfoRedux: state.userInfo,
    categoryListRedux: state.categoryList,
    insuranceListRedux: state.insuranceList,
    patientStatusListRedux: state.patientStatusList,
    hospitalListRedux: state.hospitalList,
    timestampRedux: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
class FindProviderTabPanelClass extends React.Component {
  constructor(props) {
    super(props);
    this.referralSent = false;
  
    let totalCategory = {
      1: {
        mainCategory: [],
        subCategory: [],
        childCategory: [],
      },
      2: {
        mainCategory: [],
        subCategory: [],
        childCategory: [],
      },
      3: {
        mainCategory: [],
        subCategory: [],
        childCategory: [],
      },
      4: {
        mainCategory: [],
        subCategory: [],
        childCategory: [],
      },
      5: {
        mainCategory: [],
        subCategory: [],
        childCategory: [],
      },
    };
    if (this.props.categoryListRedux) {
      if (this.props.categoryListRedux && this.props.categoryListRedux.length) {
        this.props.categoryListRedux.map((cList, key) => {
          // main loop
          if (cList.catagoryId == 1) {
            const { children } = cList;
            children.map((mChildren, mKey) => {
              if (mChildren.children && mChildren.children.length) {
                mChildren.children.map((cChildren, cKey) => {
                  totalCategory[1].childCategory.push(cChildren.catagoryId);
                });
              } else {
                totalCategory[1].subCategory.push(mChildren.catagoryId);
              }
            });
          }
          if (cList.catagoryId == 2) {
            const { children } = cList;
            children.map((mChildren, mKey) => {
              if (mChildren.children && mChildren.children.length) {
                mChildren.children.map((cChildren, cKey) => {
                  totalCategory[2].childCategory.push(cChildren.catagoryId);
                });
              } else {
                totalCategory[2].subCategory.push(mChildren.catagoryId);
              }
            });
          }
          if (cList.catagoryId == 3) {
            const { children } = cList;
            children.map((mChildren, mKey) => {
              if (mChildren.children && mChildren.children.length) {
                mChildren.children.map((cChildren, cKey) => {
                  totalCategory[3].childCategory.push(cChildren.catagoryId);
                });
              } else {
                totalCategory[3].subCategory.push(mChildren.catagoryId);
              }
            });
          }
          if (cList.catagoryId == 4) {
            const { children } = cList;
            children.map((mChildren, mKey) => {
              if (mChildren.children && mChildren.children.length) {
                mChildren.children.map((cChildren, cKey) => {
                  totalCategory[4].childCategory.push(cChildren.catagoryId);
                });
              } else {
                totalCategory[4].subCategory.push(mChildren.catagoryId);
              }
            });
          }
          if (cList.catagoryId == 5) {
            const { children } = cList;
            children.map((mChildren, mKey) => {
              if (mChildren.children && mChildren.children.length) {
                mChildren.children.map((cChildren, cKey) => {
                  totalCategory[5].childCategory.push(cChildren.catagoryId);
                });
              } else {
                totalCategory[5].subCategory.push(mChildren.catagoryId);
              }
            });
          }
        });
      }
    }
    let patientList = JSON.parse(sessionStorage.getItem(PATIENT_LIST));
    let selectedPatients = JSON.parse(
      sessionStorage.getItem(MULTIPLE_PATIENT_LIST)
    );
    this.state = {
      setLoader: this.props.setLoader ? this.props.setLoader : false,
      loading: false,
      referralLoader: false,
      patientInfo: this.props.patientInfo ? this.props.patientInfo : {},
      multiPatientMode: this.props.multiPatientMode
        ? this.props.multiPatientMode
        : false,
      items: [],
      unRegisteredStatus : false,
      registeredStatuFlag : true,
      selectedFax:"",
      page: 0,
      searchKeyword: "",
      providerIds: [],
      flagFinalize: "No",
      providerStatusArray: [],
      providerComment: "",
      providerCommentFax:"Hello.  Please see the attached patient referral information",
      dcPhone:"See Documents",
      dcLocation:"See Documents",
      dcPcpNotes:"See Documents",
      commentHeader: "",
      size: 6,
      fetchMore: false,
      categoryId: this.props.categoryId,
      patientId: localStorage.getItem("_selected_patient_list"),
      patientCriteriaModalStatus: false,
      totalSelectedProviders: 0,
      registeredCount: 0,
      unRegisteredCount: 0,
      totalCategoryIds: totalCategory,
      referralType: 1,
      confirmBoxStatus: false,
      notFound: false,
      totalProviderCount: "",
      owlCarouselOptions: {
        loop: false,
        dots: false,
        margin: 0,
        nav: true,
        items: 10,
        navText: [
          "<i class='fa fa-chevron-left'></i>",
          "<i class='fa fa-chevron-right'></i>",
        ],
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
          },
          576: {
            items: 3,
          },
          768: {
            items: 5,
          },
          850: {
            items: 3,
          },
          1100: {
            items: 4,
          },
          1200: {
            items: 5,
          },
          1400: {
            items: 6,
          },
          1600: {
            items: 8,
          },
          1800: {
            items: 10,
          },
        },
      },
      patientItemList: patientList ? patientList : [],
      multiSelectedPatients: selectedPatients ? selectedPatients : [],
      patientNotesStatus: false,
      // new work
      transportDate: moment(), //yyyy-mm-dd
      dmeEstimatestDischargeDate: moment(),
      dcDate:moment(),//yyyy-mm-dd
      transportPickupTime: "12:00 PM",
      transportPickupAddress:
        JSON.parse(localStorage.getItem(USER_INFO))?.dischargePlanner?.facility
          .address || "",
      room: this.props.patientInfo?.pccPatientsEntity?.roomDesc || "",
      transportDropAddress: "",
      steFloor: this.props.patientInfo?.pccPatientsEntity?.floorDesc || "",
      transportNeeds: "Wheelchair",
      waitTime: "00:00",
      transportDropMapObject: "{}",
      transportDropLatitude: 0,
      transportDropLongitude: 0,
      companionNeeded: false,
      physicianToSee: "",
      physicianPhone: "",
      reasonForAppointment: "",
      payor: "Patient",
      comment: "",
      dmeHeight: "",
      dmeWeight: "",
      dmePatientAddress: "",
      dmeContactInformation: "",
      dmeInsurance: "",
      dmeInsuranceMemebrId: "",
      dmeComments: "",
      dmeEquipmentNeeds: "",
      dmeDiagonsis: "",
      providerCommentFax: ''
    };
    this.providerDetailModal = this.providerDetailModal.bind(this);
    this.handlePatientReferral = this.handlePatientReferral.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleConfirmBox = this.handleConfirmBox.bind(this);
    this.handleLinkBox = this.handleLinkBox.bind(this);
    this.patientCriteriaModalOpen = this.patientCriteriaModalOpen.bind(this);
    this.patientCriteriaModalClose = this.patientCriteriaModalClose.bind(this);
    this.fetchProviders = this.fetchProviders.bind(this);
    this.sendReferralMiddle = this.sendReferralMiddle.bind(this);
    this.patientNotesOpen = this.patientNotesOpen.bind(this);
    this.searchReturn = this.searchReturn.bind(this);
  }
  async fetchProviders() {
    this.setState({
      loading: true,
      providerIds: [],
      totalSelectedProviders: 0,
    });
    this.props.loaderStatus(true);
    let url;
    let patientIds = "";
    if (this.state.multiPatientMode) {
      let selectedPatients = JSON.parse(
        sessionStorage.getItem(MULTIPLE_PATIENT_LIST)
      );
      patientIds = selectedPatients.join("-");
    } else {
      patientIds = this.state.patientId;
    }
    if (this.state.searchKeyword) {
      if (this.state.criteriaFilter) {
        url =
          apiPath.findProvider +
          "?patientId=" +
          patientIds +
          "&updatedPatientId=" +
          this.state.patientId +
          "&page=" +
          this.state.page +
          "&size=" +
          this.state.size +
          "&searchName=" +
          this.state.searchKeyword +
          "&categoryId=" +
          this.state.categoryId +
          "&" +
          this.state.criteriaFilter;
      } else {
        url =
          apiPath.findProvider +
          "?patientId=" +
          patientIds +
          "&page=" +
          this.state.page +
          "&size=" +
          this.state.size +
          "&searchName=" +
          this.state.searchKeyword +
          "&categoryId=" +
          this.state.categoryId;
      }
    } else {
      if (this.state.criteriaFilter) {
        url =
          apiPath.findProvider +
          "?patientId=" +
          patientIds +
          "&updatedPatientId=" +
          this.state.patientId +
          "&page=" +
          this.state.page +
          "&size=" +
          this.state.size +
          "&categoryId=" +
          this.state.categoryId +
          "&" +
          this.state.criteriaFilter;
      } else {
        url =
          apiPath.findProvider +
          "?patientId=" +
          patientIds +
          "&page=" +
          this.state.page +
          "&size=" +
          this.state.size +
          "&categoryId=" +
          this.state.categoryId;
      }
    }
    const response = await fetch(url, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
        } else if (response.ok) {
          let totalProviderCount = response.headers.get("X-Total-Count");
          this.setState({
            totalProviderCount: totalProviderCount,
          });
        } else if (response.status === 401) {
        } else {
        }
        return response.json();
      })
      .then((data) => {
        let items = this.state.items;
        let fetchMore = false;
        let notFound = false;
        let page = this.state.page;
        if (data.status == 400) {
          const showNotification = {
            title: "Referral",
            message: data.title,
            type: "danger",
          };
          this.props.updatePatient();
          userService.showNotification(showNotification);
        }
        if (data.length > 0) {
          if (this.state.page == 0) {
            items = data;
          } else {
            items = items.concat(data);
          }
          let totalProviderShowing = items.length;

          if (this.state.totalProviderCount > totalProviderShowing) {
            fetchMore = true;
            page = page + 1;
          }
          this.setState({
            providerIds: [],
            totalSelectedProviders: 0,
            fetchMore: fetchMore,
            page: page,
            items: items,
            totalSelectedProviders:
              this.state.totalSelectedProviders && totalProviderShowing,
          });
        } else {
          this.setState({
            providerIds: [],
            totalSelectedProviders: 0,
            fetchMore: false,
            items: [],
            notFound: true,
          });
        }
        this.props.loaderStatus(false);
      })
      .catch((error) => {
        this.props.loaderStatus(false);
        return response;
      });
    this.setState({
      loading: false,
    });
    if (this.state.criteriaFilter) {
      const showNotification = {
        title: "Patient Criteria",
        message: "Criteria filter applied successfully.",
        type: "success",
      };
      userService.showNotification(showNotification);
      this.patientCriteriaModalClose();
    }
  }
  componentDidMount() {
    // this.props.updatePatient();
    this.fetchProviders();
    document.cookie = "fModule" + " = " + "checl on finding";
  }
  componentDidUpdate() {
    if (
      this.props.reduxLoadFlag != undefined &&
      this.state.reduxLoadFlag != this.props.reduxLoadFlag
    ) {
      let categoryList = [];
      let insuranceList = [];
      let hospitalList = [];
      let patientStatusList = [];
      let userInfo = {};
      let totalCategory = {
        1: {
          mainCategory: [],
          subCategory: [],
          childCategory: [],
        },
        2: {
          mainCategory: [],
          subCategory: [],
          childCategory: [],
        },
        3: {
          mainCategory: [],
          subCategory: [],
          childCategory: [],
        },
        4: {
          mainCategory: [],
          subCategory: [],
          childCategory: [],
        },
        5: {
          mainCategory: [],
          subCategory: [],
          childCategory: [],
        },
      };
      if (this.props.categoryListRedux) {
        let selectedcategoryList = this.props.categoryListRedux;
        categoryList = selectedcategoryList ? selectedcategoryList : [];

        if (categoryList && categoryList.length) {
          categoryList.map((cList, key) => {
            // main loop
            if (cList.catagoryId == 1) {
              const { children } = cList;
              children.map((mChildren, mKey) => {
                if (mChildren.children && mChildren.children.length) {
                  mChildren.children.map((cChildren, cKey) => {
                    totalCategory[1].childCategory.push(cChildren.catagoryId);
                  });
                } else {
                  totalCategory[1].subCategory.push(mChildren.catagoryId);
                }
              });
            }
            if (cList.catagoryId == 2) {
              const { children } = cList;
              children.map((mChildren, mKey) => {
                if (mChildren.children && mChildren.children.length) {
                  mChildren.children.map((cChildren, cKey) => {
                    totalCategory[2].childCategory.push(cChildren.catagoryId);
                  });
                } else {
                  totalCategory[2].subCategory.push(mChildren.catagoryId);
                }
              });
            }
            if (cList.catagoryId == 3) {
              const { children } = cList;
              children.map((mChildren, mKey) => {
                if (mChildren.children && mChildren.children.length) {
                  mChildren.children.map((cChildren, cKey) => {
                    totalCategory[3].childCategory.push(cChildren.catagoryId);
                  });
                } else {
                  totalCategory[3].subCategory.push(mChildren.catagoryId);
                }
              });
            }
            if (cList.catagoryId == 4) {
              const { children } = cList;
              children.map((mChildren, mKey) => {
                if (mChildren.children && mChildren.children.length) {
                  mChildren.children.map((cChildren, cKey) => {
                    totalCategory[4].childCategory.push(cChildren.catagoryId);
                  });
                } else {
                  totalCategory[4].subCategory.push(mChildren.catagoryId);
                }
              });
            }
            if (cList.catagoryId == 5) {
              const { children } = cList;
              children.map((mChildren, mKey) => {
                if (mChildren.children && mChildren.children.length) {
                  mChildren.children.map((cChildren, cKey) => {
                    totalCategory[5].childCategory.push(cChildren.catagoryId);
                  });
                } else {
                  totalCategory[5].subCategory.push(mChildren.catagoryId);
                }
              });
            }
          });
        }
      }
      if (this.props.insuranceListRedux) {
        let selectedinsuranceList = this.props.insuranceListRedux;
        insuranceList = selectedinsuranceList ? selectedinsuranceList : [];
      }
      if (this.props.hospitalListRedux) {
        let selectedhospitalList = this.props.hospitalListRedux;
        hospitalList = selectedhospitalList ? selectedhospitalList : [];
      }
      if (this.props.patientStatusListRedux) {
        let selectedpatientStatusList = this.props.patientStatusListRedux;
        patientStatusList = selectedpatientStatusList
          ? selectedpatientStatusList
          : [];
      }
      if (this.props.userInfoRedux) {
        let userInfoRedux = this.props.userInfoRedux;
        userInfo = userInfoRedux ? userInfoRedux : {};
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        categoryList: categoryList,
        insuranceList: insuranceList,
        hospitalList: hospitalList,
        patientStatusList: patientStatusList,
        userInfo: userInfo,
        totalCategoryIds: totalCategory,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.patientInfo) {
      this.setState({
        patientInfo: nextProps.patientInfo,
      });
    }
  }
  componentWillUnmount() {
    this.subscription = findProviderService.clearFindProvidersList();
  }
  fetchMoreData = () => {
    this.setState(
      {
        fetchMore: false,
        criteriaFilter: "",
      },
      () => this.fetchProviders()
    );
  };
  
  searchProvider = (event) => {
    let searchKeyword = event.target.value.trim();
    this.setState({
      page: 0,
      searchKeyword: searchKeyword,
    });
  };
  getProviderId = (providerId,registeredStatus,fax) => {
    //alert(registeredStatus);
    //alert(fax);
    let countRegister = this.state.registeredCount;
    let countUnregsiter = this.state.unRegisteredCount;
  //  alert("Initial");
    //alert(countUnregsiter);
    let providerIds = this.state.providerIds;
    let allItem = this.state.items;
    let count = this.state.totalSelectedProviders;
    if (providerIds.includes(providerId)) {
    //  alert("inside 1")
      const index = providerIds.indexOf(providerId);
      if (index > -1) {
        providerIds.splice(index, 1);
        allItem.map((item) => {
          if (item.id == providerId) {
            item["isChecked"] = false;
            count -= 1;
            if(registeredStatus){
              countRegister -= 1;
            }else{
              countUnregsiter -= 1;
            }
           // alert(countUnregsiter);
          }
        });
      }
    } else {
     // alert("inside 2")
      allItem.map((item) => {
        if (item.id == providerId) {
          if(registeredStatus){
            countRegister += 1;
          }else{
            countUnregsiter +=1;
          }
         // alert(countUnregsiter);
            item["isChecked"] = true;
            count += 1;
           
        }
      });
      providerIds.push(providerId);
    //  alert(countUnregsiter);
      if(countUnregsiter >= 1 && countRegister >= 1){
      //  alert("Unregistered Already Selected");
      //  alert("inside 3");

        if (providerIds.includes(providerId)) {
        //  alert("inside 4");
          const index = providerIds.indexOf(providerId);
          if (index > -1) {
            providerIds.splice(index, 1);
            allItem.map((item) => {
              if (item.id == providerId) {
                item["isChecked"] = false;
                count -= 1;
                if(registeredStatus){
                  countRegister -= 1;
                }else{
                  countUnregsiter -= 1;
                }
              }
            });
          }
        } 

        let showNotification = {
          title: "Referral",
          message: " You can not include registered and unregistered providers in the same referral/fax. When faxing to a single unregistered provider, the referral is finalized after the fax is sent. When faxing to more than one, you will need to finalize the referral manually.",
          type: "danger",
        };
        userService.showNotification(showNotification);
       }
     
    }
    this.setState({
      providerIds: providerIds,
      totalSelectedProviders: count,
      registeredCount : countRegister,
      unRegisteredCount : countUnregsiter,
      registeredStatuFlag : registeredStatus,
      selectedFax : fax,
    });
  };
  handleAllChecked = (event) => {
    alert(this.state.unRegisteredStatus);
    let allItem = this.state.items;
    let registeredStatusCheck = false;
    let providerIds = this.state.providerIds;
    if (allItem.length && allItem.length == this.state.totalSelectedProviders) {
      providerIds = [];
      this.setState({
        providerIds: providerIds,
        totalSelectedProviders: 0,
      });
    } else {
      allItem.map((item) => {
        if (!providerIds.includes(item.id)) {
          providerIds.push(item.id);
        }
        item["isChecked"] = true;
      });
      this.setState({
        providerIds: providerIds,
        totalSelectedProviders: allItem.length,
      });
    }
      allItem.map((item) => {
            if (item.registerStatus) {
            }else{
              registeredStatusCheck=true;
              item["isChecked"] = false;             
              providerIds = [];
              this.setState({
                providerIds: providerIds,
                totalSelectedProviders: 0,
              });
            }
          });

          if(registeredStatusCheck){
            let showNotification = {
              title: "Referral",
              message: " You can not include registered and unregistered providers in the same referral/fax. When faxing to a single unregistered provider, the referral is finalized after the fax is sent. When faxing to more than one, you will need to finalize the referral manually.",
              type: "danger",
            };
            userService.showNotification(showNotification);
          }
  };
  handleConfirmBox = () => {
    //check is transport care Category
    if(!this.state.registeredStatuFlag && (this.state.selectedFax === "" || this.state.selectedFax === "websiteUrl") && this.state.flagFinalize === "No"){
      let showNotification = {
        title: "Referral",
        message: "Faxing is not available for this provider.Please use their website or other method, and then Manually Finalize",
        type: "danger",
      };
      this.setState({
        setLoader: false,
        referralLoader: false,
      });

      userService.showNotification(showNotification);
      return;
    }
    if (this.state.providerIds.length == 0) {
      let showNotification = {
        title: "Referral",
        message: "Please select at least one provider",
        type: "danger",
      };
      this.setState({
        setLoader: false,
        referralLoader: false,
      });

      userService.showNotification(showNotification);
      return;
    }
    if (
      this.state.categoryList.find((value) => value.id == this.state.categoryId)
        .categoryValue === "Transport" && this.state.flagFinalize === "No"
    ) {
      let status = this.state.transferConfirmBoxStatus ? false : true;
      this.setState({
        transferConfirmBoxStatus: status,
      });
      return;
    }
    if (
      this.state.categoryList.find((value) => value.id == this.state.categoryId)
        .categoryValue === "DME" && this.state.flagFinalize === "No"
    ) {
      let status = this.state.dmeConfirmBoxStatus ? false : true;
      this.setState({
        dmeConfirmBoxStatus: status,
      });
      return;
    }
    let status = this.state.confirmBoxStatus ? false : true;
    this.setState({
      confirmBoxStatus: status,
    });
    {this.state.flagFinalize = "YES"}{
      this.state.flagFinalize = "No";
    }
  };

  handleLinkBox = (providerId,registeredStatus) => {
    //check is transport care Category
    this.state.flagFinalize = "Yes";

    //alert(registeredStatus);
    let countRegister = this.state.registeredCount;
    let countUnregsiter = this.state.unRegisteredCount;
  //  alert("Initial");
    //alert(countUnregsiter);
    let providerIds = this.state.providerIds;
    let allItem = this.state.items;
    let count = this.state.totalSelectedProviders;
    if (providerIds.includes(providerId)) {
    } else {
     // alert("inside 2")
      allItem.map((item) => {
        if (item.id == providerId) {
          if(registeredStatus){
            countRegister += 1;
          }else{
            countUnregsiter +=1;
          }
         // alert(countUnregsiter);
            item["isChecked"] = true;
            count += 1;
           
        }
      });
      providerIds.push(providerId);
    //  alert(countUnregsiter);
      if(countUnregsiter >= 1 && countRegister >= 1){
      //  alert("Unregistered Already Selected");
      //  alert("inside 3");

        if (providerIds.includes(providerId)) {
        //  alert("inside 4");
          const index = providerIds.indexOf(providerId);
          if (index > -1) {
            providerIds.splice(index, 1);
            allItem.map((item) => {
              if (item.id == providerId) {
                item["isChecked"] = false;
                count -= 1;
                if(registeredStatus){
                  countRegister -= 1;
                }else{
                  countUnregsiter -= 1;
                }
              }
            });
          }
        } 
        let showNotification = {
          title: "Referral",
          message: " You can not include registered and unregistered providers in the same referral/fax. When faxing to a single unregistered provider, the referral is finalized after the fax is sent. When faxing to more than one, you will need to finalize the referral manually.",
          type: "danger",
        };
        userService.showNotification(showNotification);
       }
     
    }
    this.setState({
      providerIds: providerIds,
      totalSelectedProviders: count,
      registeredCount : countRegister,
      unRegisteredCount : countUnregsiter,
    });

      //check is transport care Category
      this.state.providerIds.length =1;
      if (this.state.providerIds.length == 0) {
        let showNotification = {
          title: "Referral",
          message: "Please select at least one provider",
          type: "danger",
        };
        this.setState({
          setLoader: false,
          referralLoader: false,
        });
  
        userService.showNotification(showNotification);
        return;
      }
      if (
        this.state.categoryList.find((value) => value.id == this.state.categoryId)
          .categoryValue === "Transport" && this.state.flagFinalize === "No"
      ) {
        let status = this.state.transferConfirmBoxStatus ? false : true;
        this.setState({
          transferConfirmBoxStatus: status,
        });
        return;
      }
      if (
        this.state.categoryList.find((value) => value.id == this.state.categoryId)
          .categoryValue === "DME" && this.state.flagFinalize === "No"
      ) {
        let status = this.state.dmeConfirmBoxStatus ? false : true;
        this.setState({
          dmeConfirmBoxStatus: status,
        });
        return;
      }
      let status = this.state.confirmBoxStatus ? false : true;
      this.setState({
        confirmBoxStatus: status,
      });
  };

  sendReferral = (event) => {
    event.preventDefault();
    if (!this.referralSent) {
      this.referralSent = true;
      this.setState(
        {
          referralLoader: true,
          referralSent: true,
        },
        () => this.sendReferralMiddle()
      );
    }
  };

  async sendReferralMiddle() {
    if (this.state.providerIds.length > 0) {
      this.setState({
        referralLoader: true,
        setLoader: true,
      });
      this.props.loaderStatus(true);
      const patientId = localStorage.getItem("_selected_patient_list");
      let showNotification = "";
      let patientIds = [];
      if (this.state.multiPatientMode) {
        let selectedPatients = JSON.parse(
          sessionStorage.getItem(MULTIPLE_PATIENT_LIST)
        );
        patientIds = selectedPatients;
      } else {
        patientIds.push(patientId);
      }
      if(this.state.flagFinalize == "Yes"){
        this.state.referralType="3";
      }
      if(!this.state.registeredStatuFlag && this.state.flagFinalize === "No"){
        this.state.providerComment = this.state.providerCommentFax;
      }

      let data = {
        categoryId: this.state.categoryId,
        refferalId: "",
        refferalType: this.state.referralType,
        patientId: patientIds,
        providerId: this.state.providerIds,
        flagFinalize : this.state.flagFinalize,
        dcDate:this.state.dcDate,
        dmeEstimatestDischargeDate:this.state.dmeEstimatestDischargeDate,
        dcPhone:this.state.dcPhone,
        dcLocation:this.state.dcLocation,
        comment: this.state.providerComment,
        dcPcpNotes:this.state.dcPcpNotes,
        dmeHeight: this.state.dmeHeight,
        dmeWeight: this.state.dmeWeight,
        dmePatientAddress: this.state.dmePatientAddress,
        dmeContactInformation: this.state.dmeContactInformation,
        dmeInsurance: this.state.dmeInsurance,
        dmeInsuranceMemebrId: this.state.dmeInsuranceMemebrId,
        dmeComments: this.state.dmeComments,
        dmeEquipmentNeeds: this.state.dmeEquipmentNeeds,
        dmeDiagonsis: this.state.dmeDiagonsis,
      };
      if (this.state.referralType == 1 || this.state.referralType == 3) {
        let refferalCommentBean = {
          comment: this.state.providerComment,
          providerId: this.state.providerIds,
        };
        data.refferalCommentBean = refferalCommentBean;
      }
      const response = await fetch(apiPath.sendReferral, {
        method: "POST",
        headers: generalAuthTokenHeader(),
        body: JSON.stringify(data),
        data: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 400) {
            showNotification = {
              title: "Discharge Planner",
              message: enMsg.badResponseFromServer,
              type: "danger",
            };
            this.setState({
              setLoader: false,
              referralLoader: false,
              referralSent: false,
            });
            this.referralSent = false;
            this.props.loaderStatus(false);
          } else if (response.ok) {
            showNotification = {
              title: "Referral",
              message: "Referral has been sent successfully.",
              type: "success",
            };
            if (this.state.multiPatientMode) {
              setTimeout(() => {
                sessionStorage.removeItem(SELECTED_PATIENT_LIST);
                window.location.replace(dpBaseRoutes.dashboard.path);
              }, 2000);
            } else {
              this.props.updatePatient();
            }
          } else {
            showNotification = {
              title: "Referral",
              message: enMsg.badResponseFromServer,
              type: "danger",
            };
            this.setState({
              setLoader: false,
              referralLoader: false,
              referralSent: false,
            });
            this.referralSent = false;
            this.props.loaderStatus(false);
          }
          return response.json();
        })
        .then((data) => {
          if (data.status == 400) {
            showNotification = {
              title: "Referral",
              message: data.title,
              type: "danger",
            };
          }
        })
        .catch((error) => {
          showNotification = {
            title: "Referral",
            message: enMsg.clientSideError,
            type: "danger",
          };
          this.setState({
            setLoader: false,
            referralLoader: false,
            referralSent: false,
          });
          this.referralSent = false;
          this.props.loaderStatus(false);
        });
      userService.showNotification(showNotification);
    } else {
      let showNotification = {
        title: "Referral",
        message: "Please select at least one provider",
        type: "danger",
      };
      this.setState({
        setLoader: false,
        referralLoader: false,
      });
      this.referralSent = false;
      userService.showNotification(showNotification);
    }
  }

  // transport referral
  sendTransportReferral = (event) => {
    event.preventDefault();
    if (!this.referralSent) {
      this.referralSent = true;
      this.setState(
        {
          referralLoader: true,
          referralSent: true,
        },
        () => this.sendReferralTransportMiddle()
      );
    }
  };

  async sendReferralTransportMiddle() {
    if (this.state.providerIds.length > 0) {
      this.setState({
        referralLoader: true,
        setLoader: true,
      });
      // eslint-disable-next-line react/prop-types
      this.props.loaderStatus(true);
      const patientId = localStorage.getItem("_selected_patient_list");
      let showNotification = "";
      let patientIds = [];
      if (this.state.multiPatientMode) {
        let selectedPatients = JSON.parse(
          sessionStorage.getItem(MULTIPLE_PATIENT_LIST)
        );
        patientIds = selectedPatients;
      } else {
        patientIds.push(patientId);
      }
      let data = {
        categoryId: this.state.categoryId,
        refferalId: "",
        refferalType: this.state.referralType,
        patientId: patientIds,
        providerId: this.state.providerIds,
        // new keys
        transportDate: this.state.transportDate,
        transportPickupTime: this.state.transportPickupTime,
        transportPickupAddress: this.state.transportPickupAddress,
        room: this.state.room,
        transportDropAddress: this.state.transportDropAddress,
        steFloor: this.state.steFloor,
        transportNeeds: this.state.transportNeeds,
        waitTime: this.state.waitTime,
        transportDropMapObject: this.state.transportDropMapObject,
        transportDropLatitude: this.state.transportDropLatitude,
        transportDropLongitude: this.state.transportDropLongitude,
        companionNeeded: this.state.companionNeeded,
        physicianToSee: this.state.physicianToSee,
        physicianPhone: this.state.physicianPhone,
        reasonForAppointment: this.state.reasonForAppointment,
        payor: this.state.payor,
        comment: this.state.comment,
      };
      if (this.state.referralType == 1) {
        let refferalCommentBean = {
          comment: this.state.providerComment,
          providerId: this.state.providerIds,
        };
        data.refferalCommentBean = refferalCommentBean;
      }
      // eslint-disable-next-line no-unused-vars
      const response = await fetch(apiPath.sendReferral, {
        method: "POST",
        headers: generalAuthTokenHeader(),
        body: JSON.stringify(data),
        data: JSON.stringify(data),
      })
        // eslint-disable-next-line prettier/prettier
        .then((response) => {
          if (response.status === 400) {
            showNotification = {
              title: "Discharge Planner",
              message: enMsg.badResponseFromServer,
              type: "danger",
            };
            this.setState({
              setLoader: false,
              referralLoader: false,
              referralSent: false,
            });
            this.referralSent = false;
            // eslint-disable-next-line react/prop-types
            this.props.loaderStatus(false);
          } else if (response.ok) {
            showNotification = {
              title: "Referral",
              message: "Referral has been sent successfully.",
              type: "success",
            };
            if (this.state.multiPatientMode) {
              setTimeout(() => {
                sessionStorage.removeItem(SELECTED_PATIENT_LIST);
                window.location.replace(dpBaseRoutes.dashboard.path);
              }, 2000);
            } else {
              // eslint-disable-next-line react/prop-types
              this.props.updatePatient();
            }
          } else {
            showNotification = {
              title: "Referral",
              message: enMsg.badResponseFromServer,
              type: "danger",
            };
            this.setState({
              setLoader: false,
              referralLoader: false,
              referralSent: false,
            });
            this.referralSent = false;
            // eslint-disable-next-line react/prop-types
            this.props.loaderStatus(false);
          }
          return response.json();
        })
        .then((data) => {
          if (data.status == 400) {
            showNotification = {
              title: "Referral",
              message: data.title,
              type: "danger",
            };
          }
        })
        .catch((error) => {
          showNotification = {
            title: "Referral",
            message: enMsg.clientSideError,
            type: "danger",
          };
          this.setState({
            setLoader: false,
            referralLoader: false,
            referralSent: false,
          });
          this.referralSent = false;
          // eslint-disable-next-line react/prop-types
          this.props.loaderStatus(false);
        });
      userService.showNotification(showNotification);
    } else {
      let showNotification = {
        title: "Referral",
        message: "Please select at least one provider",
        type: "danger",
      };
      this.setState({
        setLoader: false,
        referralLoader: false,
      });
      this.referralSent = false;
      userService.showNotification(showNotification);
    }
  }
  // transport referral

  patientCriteriaModalOpen = () => {
    this.setState({
      patientCriteriaModalStatus: true,
    });
  };
  patientCriteriaModalClose = () => {
    this.setState({
      patientCriteriaModalStatus: false,
    });
  };
  providerDetailModal(providerId) {
    this.props.selectedProviderDataCallback(providerId);
  }
  handlePatientReferral = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };
  
  handleChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ providerCommentFax: value });
    if (value.length <= 255){
    this.setState({
      [name]: value,
    });
    //this.setState({ providerCommentFax: value });
   }
  };

  setFilterProviderList = (filter) => {
    const showNotification = {
      title: "Criteria Filter",
      message: "Criteria filter applied successfully.",
      type: "success",
    };
    this.setState(
      {
        page: 0,
        criteriaFilter: filter,
      },
      () => {
        this.fetchProviders(filter);
      }
    );
  };
  searchReturn(event) {
    if (event.target.keyCode === 13 || event.key === "Enter") {
      event.preventDefault();
      this.fetchProviders();
    }
  }
  patientNotesOpen() {
    let notesStatus = this.state.patientNotesStatus ? false : true;
    this.setState({
      patientNotesStatus: notesStatus,
    });
  }
  render() {
    const {
      totalCategoryIds,
      providerIds,
      totalSelectedProviders,
      items,
      notFound,
      multiPatientMode,
    } = this.state;
    let availableCatUser = [];
     
    const { providerCommentFax } = this.state;
    const charLimit = 255;
    const remainingChars = charLimit - providerCommentFax.length;  // Calculate remaining characters
    
    return (
      <div>
        {this.state.categoryId && (
          <PatientCriteria
            modalStatus={this.state.patientCriteriaModalStatus}
            modalClose={this.patientCriteriaModalClose}
            selectCategoryId={this.state.categoryId}
            patientId={this.state.patientId}
            patientInfo={this.state.patientInfo}
            readOnly={false}
            setFilterProviderList={this.setFilterProviderList}
          />
        )}
        <PatientNotes
          modalStatus={this.state.patientNotesStatus}
          selectCategoryId={this.state.categoryId}
          patientInfo={this.state.patientInfo}
        />
        <Dialog
          open={this.state.confirmBoxStatus}
          fullWidth={"xl"}
          onClose={this.handleConfirmBox}
          scroll="paper"
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          className="add-modal front-modal  sendReferralConfirmationModal"
        >
          <DialogTitle className="add-modal-title" id="form-dialog-title">      
            {this.state.flagFinalize == "Yes"?"Manual Referral Confirmation":"Send Referral Confirmation"}
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.handleConfirmBox}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={this.sendReferral} noValidate>
            <DialogContent>
              {this.state.multiPatientMode ? (
                typeof this.state.patientItemList === "object" &&
                this.state.patientItemList.length &&
                this.state.multiSelectedPatients.length ? (
                  <Box>
                    <p>Patients</p>
                    <Scrollbars
                      autoHide={false}
                      universal={true}
                      autoHeight={true}
                      autoHeightMin={0}
                      autoHeightMax={50}
                      className="YOnlytScrollbars"
                    >
                      {this.state.patientItemList
                        .sort((a, b) => (a.patientId > b.patientId ? -1 : 1))
                        .map((pList, key) => {
                          if (
                            this.state.multiSelectedPatients.includes(
                              pList.patientId
                            )
                          ) {
                            return (
                              <div className="name-status-tag" key={key}>
                                <div className="name">
                                  {pList.lastName}, {pList.firstName}{" "}
                                  {pList.middleName}
                                </div>
                              </div>
                            );
                          }
                        })}
                    </Scrollbars>
                  </Box>
                ) : (
                  "No patient found"
                )
              ) : (
                <div className="name-status-tag">
                  <div className="name">
                    {this.state.patientInfo.lastName},&nbsp;{" "}
                    {this.state.patientInfo.firstName}
                  </div>
                </div>
              )}
              <div className="type">Referral Type</div>
              {this.state.items.length ? (
                      this.state.items.map((listItem, index) => {
                if (this.state.providerIds.includes(listItem.id) && listItem.registeredStatus) {
                  return (
                  <div className="type-state">
                    {REFERRAL_TYPE[this.state.referralType]}
                  </div>
                 );
                }else if(this.state.providerIds.includes(listItem.id) && this.state.flagFinalize === "No" ){
                  return (
                    <div className="type-state">
                     Fax
                    </div>
                   );
                }else if(this.state.providerIds.includes(listItem.id) && this.state.flagFinalize === "Yes"){
                  return (
                    <div className="type-state">
                     Manual
                    </div>
                   );
                }
              })
            ) : (
                    ""
            )}
              <div className="PatientList scrollx_hide">
                <p>Provider</p>
                <Scrollbars
                  autoHide={false}
                  universal={true}
                  autoHeight={true}
                  autoHeightMin={0}
                  autoHeightMax={200}
                  className="YOnlytScrollbars"
                >
                  <Box className="providerLisingMain">
                    {this.state.items.length ? (
                      this.state.items.map((listItem, index) => {
                        if (this.state.providerIds.includes(listItem.id)) {
                          return (
                            <Box
                              display="flex"
                              pr={0}
                              flexDirection="row"
                              mb={2}
                              key={listItem.id}
                            >
                              <div className="list-item-content">
                                <Box
                                  className="list-item-content-data finding-box "
                                  width={1}
                                  display="flex"
                                  flexDirection="row"
                                >
                                  <div className="user-info-tab-finalised">
                                    <div
                                      className="name"
                                      title={
                                        listItem.organizationEntity.length != 0
                                          ? listItem.organizationEntity[0]
                                              .orgName
                                          : listItem.providerName
                                      }
                                    >
                                      {listItem.organizationEntity.length != 0
                                        ? listItem.organizationEntity[0].orgName
                                        : listItem.providerName}
                                    </div>
                                    <div className="date-insurance">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14.609"
                                        height="14.608"
                                        viewBox="0 0 14.609 14.608"
                                      >
                                        <path
                                          id="phone"
                                          d="M5.94,9.324a12.228,12.228,0,0,0,5.347,5.344l1.785-1.788a.813.813,0,0,1,.825-.2,9.326,9.326,0,0,0,2.9.461.811.811,0,0,1,.812.812v2.84a.811.811,0,0,1-.812.812A13.8,13.8,0,0,1,3,3.81.811.811,0,0,1,3.81,3h2.84a.811.811,0,0,1,.812.812,9.326,9.326,0,0,0,.461,2.9.811.811,0,0,1-.2.824Z"
                                          transform="translate(-2.999 -2.998)"
                                          fill="#01a7a6"
                                        />
                                      </svg>
                                      &nbsp;
                                      <span className="phoneNumber">
                                        {listItem.user.phoneNumber
                                          ? "(" +
                                            listItem.user.phoneNumber.match(
                                              /(\d{3})(\d{3})(\d{4})/
                                            )[1] +
                                            ") " +
                                            listItem.user.phoneNumber.match(
                                              /(\d{3})(\d{3})(\d{4})/
                                            )[2] +
                                            "-" +
                                            listItem.user.phoneNumber.match(
                                              /(\d{3})(\d{3})(\d{4})/
                                            )[3]
                                          : "N/A"}
                                      </span>
                                    </div>
                                    <div className="date-insurance">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14.609"
                                        height="14.608"
                                        viewBox="0 0 13.312 15.214"
                                      >
                                        <path
                                          id="user-minus"
                                          d="M6.656,7.607a3.8,3.8,0,1,0-3.8-3.8A3.8,3.8,0,0,0,6.656,7.607Zm2.662.951h-.5a5.172,5.172,0,0,1-4.332,0h-.5A4,4,0,0,0,0,12.551v1.236a1.427,1.427,0,0,0,1.426,1.426H11.886a1.427,1.427,0,0,0,1.426-1.426V12.551A4,4,0,0,0,9.318,8.558Z"
                                          fill="#01a7a6"
                                        />
                                      </svg>
                                      <span className="phoneNumber">
                                        {listItem.user.firstName}{" "}
                                        {listItem.user.lastName}
                                      </span>
                                    </div>
                                  </div>
                                </Box>
                              </div>
                            </Box>
                          );
                        }
                      })
                    ) : (
                      <div className="no-found-text">Not Found</div>
                    )}
                  </Box>
                </Scrollbars>
              </div>
   
              {this.state.referralType == 1 && !this.state.registeredStatuFlag && this.state.flagFinalize === "No" ? (
                <Box
                  className="formModalFields"
                  width={1}
                  display="flex"
                  justifyContent="flex-start"
                  pr={2}
                  mt={0}
                  mb={2}
                >
                  <FormControl>
                    <TextField
                      rows={3}
                      multiline={true}
                      fullWidth={true}
                      name="providerCommentFax"
                      label="Comments To Provider"
                      aria-label="Comments To Provider"
                      value={this.state.providerCommentFax}
                      onChange={this.handleChangeInput}
                      inputProps={{ maxLength: charLimit }}  // Max length set to 255
                      helperText={`Remaining characters: ${remainingChars}`} 
                      className="comment-TextField scrollbar_hide"
                    />
                    <div className="type-state">
                    If Manually Finalized the comment does NOT get sent to them, but is still logged
                    </div>
                  </FormControl>
                </Box>
              ) : (
                ""
              )}

             {this.state.referralType == 1 && !this.state.registeredStatuFlag && this.state.flagFinalize === "Yes"? (
                <Box
                  className="formModalFields"
                  width={1}
                  display="flex"
                  justifyContent="flex-start"
                  pr={2}
                  mt={0}
                  mb={2}
                >
                  <FormControl>
                    <TextField
                      rows={3}
                      multiline={true}
                      fullWidth={true}
                      name="providerComment"
                      label="Comments To Provider"
                      aria-label="Comments To Provider"
                      value={this.state.providerComment}
                      onChange={this.handleChangeInput}
                      inputProps={{ maxLength: charLimit }}  // Max length set to 255
                      helperText={`Remaining characters: ${remainingChars}`} 
                      className="comment-TextField scrollbar_hide"
                    />
                    <div className="type-state">
                    If Manually Finalized the comment does NOT get sent to them, but is still logged
                    </div>
                  </FormControl>
                </Box>
              ) : (
                ""
              )}

              {this.state.referralType == 1 && this.state.registeredStatuFlag ? (
                <Box
                  className="formModalFields"
                  width={1}
                  display="flex"
                  justifyContent="flex-start"
                  pr={2}
                  mt={0}
                  mb={2}
                >
                  <FormControl>
                    <TextField
                      rows={3}
                      multiline={true}
                      fullWidth={true}
                      name="providerComment"
                      label="Comments To Provider"
                      aria-label="Comments To Provider"
                      value={this.state.providerComment}
                      onChange={this.handleChangeInput}
                      inputProps={{ maxLength: charLimit }}  // Max length set to 255
                      helperText={`Remaining characters: ${remainingChars}`} 
                      className="comment-TextField scrollbar_hide"
                    />
                    <div className="type-state">
                    If Manually Finalized the comment does NOT get sent to them, but is still logged
                    </div>
                  </FormControl>
                </Box>
              ) : (
                ""
              )}
          {this.state.items.length ? (
                      this.state.items.map((listItem, index) => {
                        let getIndex;
                        let dcDischargeDate;
                        listItem.patientBundle.map((patData,indexBundle) =>{
                          let lastname = this.state.patientInfo.lastName;
                          let bundleLatName= listItem.patientBundle[indexBundle].lastName;
                          if(lastname === bundleLatName){
                            getIndex=indexBundle;
                          }
                          })
                          if(listItem.patientBundle[getIndex]!=null){
                            dcDischargeDate =  listItem.patientBundle[getIndex].eastimatedDischargeDate;

                          }
                if (this.state.flagFinalize === "No" && this.state.providerIds.includes(listItem.id) && !listItem.registeredStatus) {
                  return ( 
                  <Box  className="formModalFields"
                  width={1}
                  display="flex"
                  justifyContent="flex-start" 
                  pr={2}
                  mt={0}
                  mb={2}>
            <FormControl variant="outlined">
                    <MuiPickersUtilsProvider
                      variant="outlined"
                      utils={DateFnsUtils}
                      moment={moment}
                    >
                      <KeyboardDatePicker
                        label="Dc Date"
                        InputLabelProps={{ className: "required-label" }}
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="start-date-picker-dialog"
                        name="dcDate"
                        animateYearScrolling={true}
                        value={this.state.dcDischargeDate}
                        onKeyDown={(e) => e.preventDefault()}
                        onChange={(event) => {
                          this.setState({
                            dcDate: event,
                            dcDischargeDate:event,
                          });
                        }}
                        className="KeyboardDatePicker"
                        closeAfterSelect={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                          className: "date-picker-span",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                </FormControl>
              </Box>
                );
              }
              })) : ("")
              }
                        {this.state.items.length ? (
                      this.state.items.map((listItem, index) => {
                if (this.state.flagFinalize === "No" && this.state.providerIds.includes(listItem.id) && !listItem.registeredStatus) {
                  return ( 
              <Box   className="formModalFields"
                  width={1}
                  display="flex"
                  justifyContent="flex-start" pr={2}
                  mt={0}
                  mb={2}>
                <TextField
                  InputLabelProps={{ className: "required-label" }}
                  rows={3}
                  multiline={true}
                  fullWidth={true}
                  name="dcLocation"
                  label="DC Location"
                  value={this.state.dcLocation}
                  onChange={this.handleChangeInput}
                  inputProps={{ maxLength: MAX_INPUT_LENGTH_LONG }}
                />
              </Box>
             				  );
                    }
                    })) : ("")
                    }
              {this.state.items.length ? (
                this.state.items.map((listItem, index) => {
          if (this.state.flagFinalize === "No" && this.state.providerIds.includes(listItem.id) && !listItem.registeredStatus) {
            return ( 
              <Box className="formModalFields"
                  width={1}
                  display="flex"
                  justifyContent="flex-start" pr={2}
                  mt={0}
                  mb={2}>
                <TextField
                  InputLabelProps={{ className: "required-label" }}
                  rows={3}
                  multiline={true}
                  fullWidth={true}
                  name="dcPhone"
                  label="Phone"
                  value={this.state.dcPhone}
                  onChange={this.handleChangeInput}
                  inputProps={{ maxLength: MAX_INPUT_LENGTH_LONG }}
                />
              </Box>
              				  );
                      }
                      })) : ("")
                      }
              {this.state.items.length ? (
                this.state.items.map((listItem, index) => {
          if (this.state.flagFinalize === "No" && this.state.providerIds.includes(listItem.id) && !listItem.registeredStatus) {
            return ( 
              <Box   className="formModalFields"
                  width={1}
                  display="flex"
                  justifyContent="flex-start" pr={2}
                  mt={0}
                  mb={2}>
                <TextField
                 rows={3}
                 multiline={true}
                 fullWidth={true}
                  InputLabelProps={{ className: "required-label" }}
                  name="dcPcpNotes"
                  label="PCP"
                  value={this.state.dcPcpNotes}
                  onChange={this.handleChangeInput}
                  inputProps={{ maxLength: MAX_INPUT_LENGTH_LONG }}
                />
              </Box>
               				  );
                      }
                      })) : ("")
                      }
            </DialogContent>
           <DialogActions className="modal-actions" justify="center">
              <Button
                type="submit"
                className="btn1"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <CircularProgress
                    size={24}
                    className="buttonProgress"
                    color="secondary"
                  />
                )}
                Submit
              </Button>
              <Button onClick={this.handleConfirmBox} className="cancel-link">
                Cancel
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          open={this.state.transferConfirmBoxStatus}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleConfirmBox}
          fullWidth={"xl"}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          className="add-modal front-modal  sendReferralConfirmationModal"
        >
          <DialogTitle className="add-modal-title" id="form-dialog-title">
            Send Referral Confirmation
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.handleConfirmBox}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={this.sendTransportReferral} noValidate>
            <DialogContent>
              {this.state.multiPatientMode ? (
                typeof this.state.patientItemList === "object" &&
                this.state.patientItemList.length &&
                this.state.multiSelectedPatients.length ? (
                  <Box>
                    <p>Patients</p>
                    <Scrollbars
                      autoHide={false}
                      universal={true}
                      autoHeight={true}
                      autoHeightMin={0}
                      autoHeightMax={50}
                      className="YOnlytScrollbars"
                    >
                      {this.state.patientItemList
                        .sort((a, b) => (a.patientId > b.patientId ? -1 : 1))
                        .map((pList, key) => {
                          if (
                            this.state.multiSelectedPatients.includes(
                              pList.patientId
                            )
                          ) {
                            return (
                              <div className="name-status-tag" key={key}>
                                <div className="name">
                                  {pList.lastName}, {pList.firstName}{" "}
                                  {pList.middleName}
                                </div>
                              </div>
                            );
                          }
                        })}
                    </Scrollbars>
                  </Box>
                ) : (
                  "No patient found"
                )
              ) : (
                <div className="name-status-tag">
                  <div className="name">
                    {this.state.patientInfo.lastName},&nbsp;{" "}
                    {this.state.patientInfo.firstName}
                  </div>
                </div>
              )}
              <div className="type">Referral Type</div>
              <div className="type-state">
                {REFERRAL_TYPE[this.state.referralType]}
              </div>
              <div className="PatientList scrollx_hide">
                <p>Provider</p>

                <Scrollbars
                  autoHide={false}
                  universal={true}
                  autoHeight={true}
                  autoHeightMin={0}
                  autoHeightMax={200}
                  className="YOnlytScrollbars"
                >
                  <Box className="providerLisingMain">
                    {this.state.items.length ? (
                      this.state.items.map((listItem, index) => {
                        if (this.state.providerIds.includes(listItem.id)) {
                          return (
                            <Box
                              display="flex"
                              pr={0}
                              flexDirection="row"
                              mb={2}
                              key={listItem.id}
                            >
                              <div className="list-item-content">
                                <Box
                                  className="list-item-content-data finding-box "
                                  width={1}
                                  display="flex"
                                  flexDirection="row"
                                >
                                  <div className="user-info-tab-finalised">
                                    <div
                                      className="name"
                                      title={
                                        listItem.organizationEntity.length != 0
                                          ? listItem.organizationEntity[0]
                                              .orgName
                                          : listItem.providerName
                                      }
                                    >
                                      {listItem.organizationEntity.length != 0
                                        ? listItem.organizationEntity[0].orgName
                                        : listItem.providerName}
                                    </div>
                                    <div className="date-insurance">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14.609"
                                        height="14.608"
                                        viewBox="0 0 14.609 14.608"
                                      >
                                        <path
                                          id="phone"
                                          d="M5.94,9.324a12.228,12.228,0,0,0,5.347,5.344l1.785-1.788a.813.813,0,0,1,.825-.2,9.326,9.326,0,0,0,2.9.461.811.811,0,0,1,.812.812v2.84a.811.811,0,0,1-.812.812A13.8,13.8,0,0,1,3,3.81.811.811,0,0,1,3.81,3h2.84a.811.811,0,0,1,.812.812,9.326,9.326,0,0,0,.461,2.9.811.811,0,0,1-.2.824Z"
                                          transform="translate(-2.999 -2.998)"
                                          fill="#01a7a6"
                                        />
                                      </svg>
                                      &nbsp;
                                      <span className="phoneNumber">
                                        {listItem.user.phoneNumber
                                          ? "(" +
                                            listItem.user.phoneNumber.match(
                                              /(\d{3})(\d{3})(\d{4})/
                                            )[1] +
                                            ") " +
                                            listItem.user.phoneNumber.match(
                                              /(\d{3})(\d{3})(\d{4})/
                                            )[2] +
                                            "-" +
                                            listItem.user.phoneNumber.match(
                                              /(\d{3})(\d{3})(\d{4})/
                                            )[3]
                                          : "N/A"}
                                      </span>
                                    </div>
                                    <div className="date-insurance">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14.609"
                                        height="14.608"
                                        viewBox="0 0 13.312 15.214"
                                      >
                                        <path
                                          id="user-minus"
                                          d="M6.656,7.607a3.8,3.8,0,1,0-3.8-3.8A3.8,3.8,0,0,0,6.656,7.607Zm2.662.951h-.5a5.172,5.172,0,0,1-4.332,0h-.5A4,4,0,0,0,0,12.551v1.236a1.427,1.427,0,0,0,1.426,1.426H11.886a1.427,1.427,0,0,0,1.426-1.426V12.551A4,4,0,0,0,9.318,8.558Z"
                                          fill="#01a7a6"
                                        />
                                      </svg>
                                      <span className="phoneNumber">
                                        {listItem.user.firstName}{" "}
                                        {listItem.user.lastName}
                                      </span>
                                    </div>
                                  </div>
                                </Box>
                              </div>
                            </Box>
                          );
                        }
                      })
                    ) : (
                      <div className="no-found-text">Not Found</div>
                    )}
                  </Box>
                </Scrollbars>
              </div>

              <Box display="flex" justifyContent="flex-start" mb={1}>
                <Box width={1 / 2} pr={2}>
                  <FormControl variant="outlined">
                    <MuiPickersUtilsProvider
                      variant="outlined"
                      utils={DateFnsUtils}
                      // utils={UTCUtils}
                      moment={moment}
                    >
                      <KeyboardDatePicker
                        label="Date"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="start-date-picker-dialog"
                        InputLabelProps={{
                          className: "required-label",
                        }}
                        name="transportDate"
                        animateYearScrolling={true}
                        value={this.state.transportDate}
                        onKeyDown={(e) => e.preventDefault()}
                        // maxDate={this.maxDate}
                        minDate={moment()}
                        //   maxDateMessage={enMsg.dobMaxDate}
                        onChange={(event) => {
                          this.setState({
                            transportDate: event,
                          });
                        }}
                        // onError={(error, value) => {
                        //   console.log({ error, value });
                        //   this.setState({
                        //     transportDateValid:
                        //       error || value == "" || value == null
                        //         ? false
                        //         : true,
                        //   });
                        // }}
                        className="KeyboardDatePicker"
                        //  invalidDateMessage={enMsg.invalidDate}
                        closeAfterSelect={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                          className: "date-picker-span",
                        }}
                        //disabled={isPatientImported ? true : false}
                      />
                    </MuiPickersUtilsProvider>
                    {/* <FormErrors
                    show={!this.state.dobValid}
                    formErrors={this.state.formErrors}
                    fieldName="dob"
                  /> */}
                  </FormControl>
                </Box>
                <Box width={1 / 2} pr={2}>
                  <FormControl>
                    <label className="MuiFormLabel-root MuiInputLabel-root required-label MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
                      Pickup Time
                    </label>
                    <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
                      <DatePicker
                        //  selected={null}
                        onChange={(date) => {
                          this.setState({
                            transportPickupTime: moment(date)
                              .local()
                              .format("hh:mm A"),
                          });
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        value={this.state.transportPickupTime}
                        //  dateFormat={dateFormat.TIME_FORMAT}
                        //   timeFormat={dateFormat.TIME_FORMAT}
                        placeholderText="Pickup Time"
                        className="MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedEnd"
                        popperPlacement="top"

                        // disabled={!check.includes(day)}
                      />
                    </div>
                  </FormControl>
                </Box>
              </Box>

              <Box display="flex" justifyContent="flex-start" mb={1}>
                <Box width={1 / 2} pr={2}>
                  <TextField
                    label="Pickup Address"
                    //   InputLabelProps={{ className: "required-label" }}
                    type="text"
                    name="transportPickupAddress"
                    autoComplete="off"
                    value={this.state.transportPickupAddress}
                    // data-validators="isRequired,isAlpha"
                    onChange={(event) => {
                      this.setState({
                        [event.target.name]: event.target.value,
                      });
                    }}
                  />
                  {/* <FormErrors
                    show={!this.state.firstNameValid}
                    formErrors={this.state.formErrors}
                    fieldName="firstName"
                  /> */}
                </Box>
                <Box width={1 / 2} pr={2}>
                  <TextField
                    label="Room"
                    // InputLabelProps={{ className: "required-label" }}
                    type="text"
                    name="room"
                    autoComplete="off"
                    value={this.state.room}
                    //   data-validators="isRequired,isAlpha"
                    onChange={(event) => {
                      this.setState({
                        [event.target.name]: event.target.value,
                      });
                    }}
                  />
                  {/* <FormErrors
                    show={!this.state.firstNameValid}
                    formErrors={this.state.formErrors}
                    fieldName="firstName"
                  /> */}
                </Box>
              </Box>

              <Box display="flex" justifyContent="flex-start" mb={1}>
                <Box width={1 / 2} pr={2}>
                  <AutoComplete
                    setAddress={(address) => {
                      this.setState({
                        transportDropMapObject: JSON.stringify(address),
                        transportDropAddress: address.address,
                        transportDropLatitude: address.lat,
                        transportDropLongitude: address.lng,
                      });
                    }}
                  />
                </Box>
                <Box width={1 / 2} pr={2}>
                  <TextField
                    label="STE/Floor"
                    //InputLabelProps={{ className: "required-label" }}
                    type="text"
                    name="steFloor"
                    autoComplete="off"
                    value={this.state.steFloor}
                    onChange={(event) => {
                      this.setState({
                        [event.target.name]: event.target.value,
                      });
                    }}
                    // data-validators="isRequired,isAlpha"
                    //onChange={this.handleUserInput}
                  />
                  {/* <FormErrors
                    show={!this.state.firstNameValid}
                    formErrors={this.state.formErrors}
                    fieldName="firstName"
                  /> */}
                </Box>
              </Box>

              <Box display="flex" justifyContent="flex-start" mb={1}>
                <Box width={1 / 2} pr={2}>
                  <FormControl>
                    <InputLabel className={"required-label"}>Needs</InputLabel>
                    <Select
                      id="transportNeeds"
                      color="secondary"
                      name="transportNeeds"
                      value={this.state.transportNeeds}
                      onChange={this.handleChangeInput}
                    >
                      {TRASPORT_NEEDS
                        ? Object.keys(TRASPORT_NEEDS).map((key) => {
                            return (
                              <MenuItem
                                key={TRASPORT_NEEDS[key]}
                                value={TRASPORT_NEEDS[key]}
                              >
                                {TRASPORT_NEEDS[key]}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                    {/* <FormErrors
                      show={!this.state.priorityValid}
                      formErrors={this.state.formErrors}
                      fieldName="priority"
                    /> */}
                  </FormControl>
                </Box>
                <Box width={1 / 2} pr={2}>
                  <FormControl>
                    <label className="MuiFormLabel-root MuiInputLabel-root required-label MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
                      Wait Time
                    </label>
                    <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
                      <DatePicker
                        timeIntervals={30}
                        //   selected={selectedStartTime(day)}
                        //  onChange={(date) => handleChangeStartTime(date, day)}
                        onChange={(date) => {
                          this.setState({
                            waitTime: moment(date)
                              .local()
                              .format("HH:mm"),
                          });
                        }}
                        value={this.state.waitTime}
                        showTimeSelect
                        showTimeSelectOnly
                        timeCaption="Time"
                        //  dateFormat={dateFormat.TIME_FORMAT}
                        timeFormat={"HH:mm"}
                        placeholderText="Wait Time"
                        className="MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedEnd"
                        popperPlacement="top"
                        // disabled={!check.includes(day)}
                      />
                    </div>
                  </FormControl>
                </Box>
              </Box>

              <Box display="flex" justifyContent="flex-start" mb={1}>
                <Box width={1 / 2} pr={2} mt={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.companionNeeded}
                        onClick={(event) => {
                          this.setState((prevState) => {
                            return {
                              companionNeeded: !prevState.companionNeeded,
                            };
                          });
                        }}
                      />
                    }
                    label="Companion needed"
                  />
                </Box>
                <Box width={1 / 2} pr={2}>
                  <TextField
                    label="Physician to see"
                    //InputLabelProps={{ className: "required-label" }}
                    type="text"
                    name="physicianToSee"
                    autoComplete="off"
                    value={this.state.physicianToSee}
                    onChange={(event) => {
                      this.setState({
                        [event.target.name]: event.target.value,
                      });
                    }}
                    // data-validators="isRequired,isAlpha"
                    //onChange={this.handleUserInput}
                  />
                  {/* <FormErrors
                    show={!this.state.firstNameValid}
                    formErrors={this.state.formErrors}
                    fieldName="firstName"
                  /> */}
                </Box>
              </Box>

              {/* <Box
                className="formModalFields"
                width={1}
                display="flex"
                justifyContent="flex-start"
                pr={2}
                mt={0}
                mb={2}
              ></Box> */}

              <Box display="flex" justifyContent="flex-start" mb={1}>
                <Box width={1 / 2} pr={2}>
                  <TextField
                    label="Physician phone"
                    //InputLabelProps={{ className: "required-label" }}
                    type="text"
                    name="physicianPhone"
                    autoComplete="off"
                    value={this.state.physicianPhone}
                    onChange={(event) => {
                      this.setState({
                        [event.target.name]: event.target.value,
                      });
                    }}
                    // data-validators="isRequired,isAlpha"
                    //onChange={this.handleUserInput}
                  />
                  {/* <FormErrors
                    show={!this.state.firstNameValid}
                    formErrors={this.state.formErrors}
                    fieldName="firstName"
                  /> */}
                </Box>
                <Box width={1 / 2} pr={2}>
                  <FormControl>
                    <InputLabel className={"required-label"}>Payor</InputLabel>
                    <Select
                      id="payor"
                      color="secondary"
                      name="payor"
                      value={this.state.payor}
                      onChange={this.handleChangeInput}
                    >
                      {TRASPORT_PAYOR
                        ? Object.keys(TRASPORT_PAYOR).map((key) => {
                            return (
                              <MenuItem
                                key={TRASPORT_PAYOR[key]}
                                value={TRASPORT_PAYOR[key]}
                              >
                                {TRASPORT_PAYOR[key]}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                  </FormControl>

                  {/* <FormErrors
                      show={!this.state.priorityValid}
                      formErrors={this.state.formErrors}
                      fieldName="priority"
                    /> */}
                </Box>
              </Box>

              <Box
                className="formModalFields"
                width={1}
                display="flex"
                justifyContent="flex-start"
                pr={2}
                mt={0}
                mb={2}
              >
                <FormControl>
                  <TextField
                    // rows={4}
                    // multiline={true}
                    // fullWidth={true}

                    label="Reason for appointment"
                    //InputLabelProps={{ className: "required-label" }}
                    type="text"
                    name="reasonForAppointment"
                    autoComplete="off"
                    value={this.state.reasonForAppointment}
                    onChange={(event) => {
                      this.setState({
                        [event.target.name]: event.target.value,
                      });
                    }}
                    // type="text"
                    // name="reasonForAppointment"
                    // label="Reason for appointment"
                    // aria-label="Reason for appointment"
                    // value={this.state.reasonForAppointment}
                    // onChange={this.handleChangeInput}
                    //inputProps={{ maxLength: MAX_INPUT_LENGTH_LONG }}
                    //   className="comment-TextField scrollbar_hide"
                  />
                </FormControl>
              </Box>

              <Box
                className="formModalFields"
                width={1}
                display="flex"
                justifyContent="flex-start"
                pr={2}
                mt={0}
                mb={2}
              >
                <FormControl>
                  <TextField
                    rows={4}
                    multiline={true}
                    fullWidth={true}
                    name="comment"
                    label="Appointment details"
                    aria-label="Comments"
                    value={this.state.comment}
                    onChange={this.handleChangeInput}
                    inputProps={{ maxLength: MAX_INPUT_LENGTH_LONG }}
                    className="comment-TextField scrollbar_hide"
                  />
                </FormControl>
              </Box>
              {this.state.referralType == 1 ? (
                <Box
                  className="formModalFields"
                  width={1}
                  display="flex"
                  justifyContent="flex-start"
                  pr={2}
                  mt={0}
                  mb={2}
                >
                  <FormControl>
                    <TextField
                      rows={4}
                      multiline={true}
                      fullWidth={true}
                      name="providerComment"
                      label="Comments To Provider"
                      aria-label="Comments To Provider"
                      value={this.state.providerComment}
                      onChange={this.handleChangeInput}
                      inputProps={{ maxLength: charLimit }}  // Max length set to 255
                      helperText={`Remaining characters: ${remainingChars}`} 
                      className="comment-TextField scrollbar_hide"
                    />
                    <div className="type-state">
                    If Manually Finalized the comment does NOT get sent to them, but is still logged
                    </div>
                  </FormControl>
                </Box>
              ) : (
                ""
              )}
            </DialogContent>

            <DialogActions className="modal-actions" justify="center">
              <Button
                type="submit"
                className="btn1"
                disabled={
                  this.state.loading || this.state.transportDropAddress == ""
                }
              >
                {this.state.loading && (
                  <CircularProgress
                    size={24}
                    className="buttonProgress"
                    color="secondary"
                  />
                )}
                Submit
              </Button>
              <Button onClick={this.handleConfirmBox} className="cancel-link">
                Cancel
              </Button>
            </DialogActions>
          </form>
        </Dialog>        {/* Transport Popup */}


       {/* Dme Popup */}
        <Dialog
          open={this.state.dmeConfirmBoxStatus}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleConfirmBox}
          fullWidth={"xl"}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          className="add-modal front-modal  sendReferralConfirmationModal"
        >
          <DialogTitle className="add-modal-title" id="form-dialog-title">
            Send Referral Confirmation
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.handleConfirmBox}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={this.sendReferral} noValidate>
            <DialogContent>
              {this.state.multiPatientMode ? (
                typeof this.state.patientItemList === "object" &&
                this.state.patientItemList.length &&
                this.state.multiSelectedPatients.length ? (
                  <Box>
                    <Scrollbars
                      autoHide={false}
                      universal={true}
                      autoHeight={true}
                      autoHeightMin={0}
                      autoHeightMax={200}
                      className="YOnlytScrollbars"
                    >
                      {this.state.patientItemList
                        .sort((a, b) => (a.patientId > b.patientId ? -1 : 1))
                        .map((pList, key) => {
                          if (
                            this.state.multiSelectedPatients.includes(
                              pList.patientId
                            )
                          ) {
                            return (
                              <div className="name-status-tag" key={key}>
                                 Demographics
                                <div className="name">
                                  {pList.lastName}, {pList.firstName}{" "}
                                  {pList.middleName}
                                </div>
                              </div>
                            );
                          }
                        })}
                    </Scrollbars>
                  </Box>
                ) : (
                  "No patient found"
                )
              ) : (
                <div className="name-status-tag">
                  <div className="name">
                    <h5><b>Demographics</b></h5>
                    {this.state.patientInfo.lastName},&nbsp;{" "}
                    {this.state.patientInfo.firstName}
                  </div>
                </div>
              )}
              <Box display="flex" justifyContent="flex-start" mb={1}>
                <Box width={1 / 2} pr={1}>
                  <TextField
                    label="Height"
                    //   InputLabelProps={{ className: "required-label" }}
                    type="text"
                    id="dmeHeight"
                    name="dmeHeight"
                    autoComplete="off"
                    value={this.state.dmeHeight}
                    // data-validators="isRequired,isAlpha"
                    onChange={(event) => {
                      this.setState({
                        [event.target.name]: event.target.value,
                      });
                    }}
                  />
                </Box>
                <Box width={1 / 2} pr={1}>
                  <TextField
                    label="Weight"
                    // InputLabelProps={{ className: "required-label" }}
                    type="text"
                    id="dmeWeight"
                    name="dmeWeight"
                    autoComplete="off"
                    value={this.state.dmeWeight}
                    //   data-validators="isRequired,isAlpha"
                    onChange={(event) => {
                      this.setState({
                        [event.target.name]: event.target.value,
                      });
                    }}
                  />
                </Box>
              </Box>
              <Box
                className="formModalFields"
                width={1}
                display="flex"
                justifyContent="flex-start"
                pr={2}
                mt={0}
                mb={2}
              >
                <FormControl>
                  <TextField
                    // rows={4}
                    // multiline={true}
                    // fullWidth={true}
                    label="Patient Address"
                    //InputLabelProps={{ className: "required-label" }}
                    type="text"
                    id="dmePatientAddress"
                    name="dmePatientAddress"
                    autoComplete="off"
                    value={this.state.dmePatientAddress}
                    onChange={(event) => {
                      this.setState({
                        [event.target.name]: event.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Box>

              <Box display="flex" justifyContent="flex-start" mb={1}>
              <Box width={1 / 2} pr={2}>
                  <TextField
                    label="Contact Information"
                    InputLabelProps={{ className: "required-label" }}
                    type="text"
                    id="dmeContactInformation"
                    name="dmeContactInformation"
                    autoComplete="off"
                    value={this.state.dmeContactInformation}
                    //   data-validators="isRequired,isAlpha"
                    onChange={(event) => {
                      this.setState({
                        [event.target.name]: event.target.value,
                      });
                    }}
                  />
                </Box>
                {this.state.items.length ? (
                      this.state.items.map((listItem, index) => {
                        let getIndex;
                        listItem.patientBundle.map((patData,indexBundle) =>{
                          let lastname = this.state.patientInfo.lastName;
                          let bundleLatName= listItem.patientBundle[indexBundle].lastName;
                          if(lastname === bundleLatName){
                            getIndex=indexBundle;
                          }
                          })
                        if (this.state.providerIds.includes(listItem.id)) {
                          return (
                <Box width={1 / 2} pr={2}>
                  <FormControl variant="outlined">
                    <MuiPickersUtilsProvider
                      variant="outlined"
                      utils={DateFnsUtils}
                      // utils={UTCUtils}
                      moment={moment}
                    >
                      <KeyboardDatePicker
                        label="Estimated Discharge Date"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="start-date-picker-dialog"
                        // InputLabelProps={{
                        //   className: "required-label",
                        // }}
                        name="dmeEstimatestDischargeDate"
                        animateYearScrolling={true}
                        value={listItem.patientBundle[getIndex]!=null?listItem.patientBundle[getIndex].eastimatedDischargeDate:""}
                        onKeyDown={(e) => e.preventDefault()}
                        // maxDate={this.maxDate}
                        //minDate={moment()}
                        //   maxDateMessage={enMsg.dobMaxDate}
                        onChange={(event) => {
                          this.setState({
                            dmeEstimatestDischargeDate: event,
                          });
                        }}
                        // onError={(error, value) => {
                        //   console.log({ error, value });
                        //   this.setState({
                        //     transportDateValid:
                        //       error || value == "" || value == null
                        //         ? false
                        //         : true,
                        //   });
                        // }}
                        className="KeyboardDatePicker"
                        //  invalidDateMessage={enMsg.invalidDate}
                        closeAfterSelect={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                          className: "date-picker-span",
                        }}
                        //disabled={isPatientImported ? true : false}
                      />
                    </MuiPickersUtilsProvider>
                    {/* <FormErrors
                    show={!this.state.dobValid}
                    formErrors={this.state.formErrors}
                    fieldName="dob"
                  /> */}
                  </FormControl>
                </Box>
               						   );
                            }
                          })
                        ) : (
                         ""
                        )}
              </Box>

              <Box display="flex" justifyContent="flex-start" mb={1}>
              {this.state.items.length ? (
                      this.state.items.map((listItem, index) => {
                        let getIndex;
                        listItem.patientBundle.map((patData,indexBundle) =>{
                          let lastname = this.state.patientInfo.lastName;
                          let bundleLatName= listItem.patientBundle[indexBundle].lastName;
                          if(lastname === bundleLatName){
                            getIndex=indexBundle;
                          }
                          })
                        if (this.state.providerIds.includes(listItem.id)) {
                          return (

                <Box width={1 / 2} pr={2}>
                  <TextField
                    label="Insurance"
                    //   InputLabelProps={{ className: "required-label" }}
                    type="text"
                    id="dmeInsurance"
                    name="dmeInsurance"
                    autoComplete="off"
                    value={listItem.patientBundle[getIndex]!=null?listItem.patientBundle[getIndex].masterInsurance!=null?listItem.patientBundle[getIndex].masterInsurance.insuranceName:"No Insurance":"No Insurance"}
                    // data-validators="isRequired,isAlpha"
                    onChange={(event) => {
                      this.setState({
                        [event.target.name]: event.target.value,
                      });
                    }}
                  />
                  {/* <FormErrors
                    show={!this.state.firstNameValid}
                    formErrors={this.state.formErrors}
                    fieldName="firstName"
                  /> */}
                </Box>
              );
                            }
                          })
                        ) : (
                         ""
                        )}

                <Box width={1 / 2} pr={2}>
                  <TextField
                    label="Insurance Member Id"
                    // InputLabelProps={{ className: "required-label" }}
                    type="text"
                    id="dmeInsuranceMemebrId"
                    name="dmeInsuranceMemebrId"
                    autoComplete="off"
                    value={this.state.dmeInsuranceMemebrId}
                    //   data-validators="isRequired,isAlpha"
                    onChange={(event) => {
                      this.setState({
                        [event.target.name]: event.target.value,
                      });
                    }}
                  />
                  {/* <FormErrors
                    show={!this.state.firstNameValid}
                    formErrors={this.state.formErrors}
                    fieldName="firstName"
                  /> */}
                </Box>
              </Box>


              <Box
                className="formModalFields"
                width={1}
                display="flex"
                justifyContent="flex-start"
                pr={2}
                mt={0}
                mb={2}
              >
                <FormControl>
                  <TextField
                    rows={3}
                    multiline={true}
                    fullWidth={true}
                    id="dmeComments"
                    name="dmeComments"
                    label="Comments"
                    aria-label="Comments"
                    value={this.state.dmeComments}
                    onChange={this.handleChangeInput}
                    inputProps={{ maxLength: MAX_INPUT_LENGTH_LONG }}
                    className="comment-TextField scrollbar_hide"
                  />
                </FormControl>
              </Box>
              <h5><b>Equipment</b></h5>
              <Box  className="formModalFields"
                width={1}
                display="flex"
                justifyContent="flex-start"
                pr={0}
                mt={0}
                mb={0}>
                  <TextField
                    rows={3}
                    multiline={true}
                    fullWidth={true}
                    label="Equipment Needs"
                    //   InputLabelProps={{ className: "required-label" }}
                    type="text"
                    id="dmeEquipmentNeeds"
                    name="dmeEquipmentNeeds"
                    autoComplete="off"
                    value={this.state.dmeEquipmentNeeds}
                    // data-validators="isRequired,isAlpha"
                    onChange={(event) => {
                      this.setState({
                        [event.target.name]: event.target.value,
                      });
                    }}
                  />
                  {/* <FormErrors
                    show={!this.state.firstNameValid}
                    formErrors={this.state.formErrors}
                    fieldName="firstName"
                  /> */}
                </Box>
                <Box  className="formModalFields"
                width={1}
                display="flex"
                justifyContent="flex-start"
                pr={1}
                mt={0}
                mb={1}>
                  <TextField
                    rows={3}
                    multiline={true}
                    fullWidth={true}
                    label="Diagnosis and ICD-10 code"
                    // InputLabelProps={{ className: "required-label" }}
                    type="text"
                    id="dmeDiagonsis"
                    name="dmeDiagonsis"
                    autoComplete="off"
                    value={this.state.dmeDiagonsis}
                    //   data-validators="isRequired,isAlpha"
                    onChange={(event) => {
                      this.setState({
                        [event.target.name]: event.target.value,
                      });
                    }}
                  />
                  {/* <FormErrors
                    show={!this.state.firstNameValid}
                    formErrors={this.state.formErrors}
                    fieldName="firstName"
                  /> */}
                </Box>
            </DialogContent>

            <DialogActions className="modal-actions" justify="center">
              <Button
                type="submit"
                className="btn1"
                disabled={
                  this.state.loading || this.state.dmeContactInformation == ""
                }
              >
                {this.state.loading && (
                  <CircularProgress
                    size={24}
                    className="buttonProgress"
                    color="secondary"
                  />
                )}
                Submit
              </Button>
              <Button onClick={this.handleConfirmBox} className="cancel-link">
                Cancel
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Box
          display="flex"
          flexDirection="row"
          width={1}
          className="patient-list-page AwaitingProviderTabPanelCSS  patient-list-page-new patient-info-page-new FindProviderTab"
        >
          <Box pr={1} pl={0} className="left-content-box ">
            <div className="left-content-header">
              <Box
                display="flex"
                pr={2}
                className="divcontainer-tab refBoxInMiddle"
                mb={2}
              >
                <Box pr={1} className="title-box">
                  {this.state.multiPatientMode ? (
                    <h2 className="titlename">
                      Filtered List for{" "}
                      {this.state.multiSelectedPatients
                        ? this.state.multiSelectedPatients.length
                        : 0}{" "}
                      patients
                    </h2>
                  ) : (
                    <h2 className="titlename">Filtered List</h2>
                  )}
                </Box>
                <FormGroup className="box-filter">
                  {this.state.multiPatientMode ? (
                    <Box className="title-box">
                      <h4 className="label">Referral Type</h4>
                      <h4 className="value">{REFERRAL_TYPE[0]}</h4>
                    </Box>
                  ) : (
                    <FormControl variant="outlined">
                      <InputLabel>Referral Type</InputLabel>
                      <Select
                        id="filter"
                        color="secondary"
                        name="referralType"
                        className="filter-select"
                        value={this.state.referralType}
                        onChange={this.handlePatientReferral}
                      >
                        {REFERRAL_TYPE
                          ? Object.keys(REFERRAL_TYPE).map((key) => {
                              return (
                                <MenuItem key={key} value={key}>
                                  {REFERRAL_TYPE[key]}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                  )}
                  <Box pl={1} pr={1}>
                    <Tooltip title="Search By Name" placement="top" arrow>
                      <Paper component="form" className="search-box-cover">
                        <InputBase
                          className="search-box"
                          placeholder="Enter search here..."
                          onChange={this.searchProvider}
                          onKeyPress={this.searchReturn}
                        />
                        <IconButton
                          className=""
                          aria-label="search"
                          onClick={this.fetchProviders}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    </Tooltip>
                  </Box>
                </FormGroup>
                <Box
                  className="action-box-tab"
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                >
                  <Box pl={1} className="action-filter">
                    <button
                      className="pointer btn4"
                      onClick={this.handleAllChecked}
                    >
                      {items &&
                      items.length &&
                      items.length == totalSelectedProviders
                        ? "Deselect all"
                        : "Select all"}
                      ({this.state.totalSelectedProviders})
                    </button>
                  </Box>
                  <Box pl={1} className="action-filter">
                    <button
                      className="btn1"
                      onClick={this.handleConfirmBox}
                    >
                      {this.state.referralLoader && (
                        <CircularProgress
                          size={24}
                          className="buttonProgress"
                        />
                      )}
                      Send Referral
                    </button>
                  </Box>
                </Box>
              </Box>
            </div>
            {items && items.length ? (
              <Box
                width={1}
                className="left-content-list left-content-list-New"
              >
                <div className="PatientList">
                  <Scrollbars
                    style={{ height: "calc(100vh - 518px)" }}
                    universal={true}
                    className="YOnlytScrollbars TabScroll"
                  >
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={this.fetchMoreData}
                      hasMore={this.state.fetchMore}
                      loader={
                        <div className="loader" key={0}>
                          Loading ...
                        </div>
                      }
                      useWindow={false}
                    >
                      {items && items.length ? (
                        items
                          .sort((a, b) =>
                            a.providerName > b.providerName ? 1 : -1
                          )
                          .map((listItem, index) => (
                            <Box
                              width={1}
                              display="flex"
                              pr={2}
                              flexDirection="row"
                              mb={2}
                              key={listItem.id}
                            >
                              <div className="list-item-checkbox">
                                <Checkbox
                                  className="check-input"
                                  checked={
                                    providerIds.includes(listItem.id)
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    this.getProviderId(listItem.id,listItem.registeredStatus,listItem.organizationEntity[0]
                                      .primaryMethod)
                                  }
                                />
                              </div>
                              <div className="list-item-content">
                                <Box
                                  className="list-item-content-data finding-box "
                                  width={1}
                                  display="flex"
                                  flexDirection="row"
                                >
                                  {(listItem.registeredStatus)?"":this.state.unRegisteredStatus}
                                  <Box
                                    pr={1}
                                    className="pointer w-100"
                                    onClick={() =>
                                      this.providerDetailModal(listItem.id)
                                    }
                                  >

                                    <div className="user-info-tab w-100">
                                      <div
                                        className=""
                                        title={
                                          listItem.organizationEntity.length !=
                                          0
                                            ? listItem.organizationEntity[0]
                                                .orgName
                                            : listItem.providerName
                                        }
                                      >
                                        {/* {listItem.organizationEntity.length != 0
                                          ? listItem.organizationEntity[0]
                                              .orgName
                                          : listItem.providerName} */}
                                        {listItem.providerName}{" "} ...
                                        <span className="infoicon-svg">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 15 15"
                                          >
                                            <path
                                              id="information_outline"
                                              data-name="information outline"
                                              d="M7.4,6.2H8.6V5H7.4M8,12.8A4.8,4.8,0,1,1,12.8,8,4.806,4.806,0,0,1,8,12.8ZM8,2a6,6,0,1,0,6,6A6,6,0,0,0,8,2Zm-.6,9H8.6V7.4H7.4Z"
                                              transform="translate(-1.999 -1.998)"
                                              fill="#0D81B4"
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                      
                                      <div className="date-insurance">
                                        <span className="phoneNumber">
                                          {listItem.user.phoneNumber
                                            ? "(" +
                                              listItem.user.phoneNumber.match(
                                                /(\d{3})(\d{3})(\d{4})/
                                              )[1] +
                                              ") " +
                                              listItem.user.phoneNumber.match(
                                                /(\d{3})(\d{3})(\d{4})/
                                              )[2] +
                                              "-" +
                                              listItem.user.phoneNumber.match(
                                                /(\d{3})(\d{3})(\d{4})/
                                              )[3]
                                            : "N/A"}
                                        </span>
                                      </div>
                                      <div className="date-insurance">
                                        <span className="phoneNumber">
                                        {listItem.organizationEntity.map(
                                            (entity, index) => (
                                              <div key={index}>
                                              {entity.fax ? (
                                                <React.Fragment>
                                                <span className="map-svg">
                                                  <PrintIcon/>
                                                </span>
                                                <span className="phoneNumber">  
                                                  { entity.fax }
                                                </span> 
                                                </React.Fragment>
                                                ):(
                                                <React.Fragment>
                                                <span className="map-svg">
                                                  <LanguageIcon/>
                                                </span>
                                                <span className="phoneNumber">  
                                                  <a href="#" onClick={e => e.preventDefault()}>
                                                     {entity.websiteUrl}
                                                  </a>
                                                </span>
                                                </React.Fragment>
                                                )}
                                              </div>
                                            )
                                          )}
                                        </span>
                                      </div>
                                      <div>
                                        <div>
                                          {listItem.organizationEntity.map(
                                            (entity, index) => (
                                              <div key={index}>
                                                <span className="map-svg">
                                                  <img src={mapMarker} />
                                                </span>
                                                <span className="address-text-bold">
                                                  {entity.orgName
                                                    ? entity.orgName + " : "
                                                    : ""}{" "}
                                                  {entity.addressStreet}
                                                </span>
                                              </div>
                                            )
                                          )}
                                          {listItem.otherFilteredAddressEntities.map(
                                            (entity, index) => (
                                              <div key={index}>
                                                <span className="map-svg">
                                                  <img src={mapMarker} />
                                                </span>
                                                <span>
                                                  {entity.title
                                                    ? entity.title + " : "
                                                    : ""}
                                                  {entity.addressStreet}
                                                </span>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </Box>  
                                  <Box pr={2} pl={1} width="400px"
                                  className={"section btns-cover"}>                         
                                    {listItem.organizationEntity.map(
                                            (entity, index) => (
                                              <div key={index}>
                                              {!listItem.registeredStatus && entity.websiteUrl ? ( 
                                                 <button className="btn1">
                                                  <a  href={
                                                        entity.websiteUrl.startsWith(
                                                          "http"
                                                        )
                                                          ? entity.websiteUrl
                                                          : "https://" +
                                                          entity.websiteUrl
                                                      }  target="_blank"
                                                      rel="noopener noreferrer">  
                                                      <font color="#FFFFFF">Website Link</font>
                                                       
                                                  </a>
                                                </button>
                                                ):""}
                                              </div>
                                            )
                                          )}
            
                                {listItem.organizationEntity.map(
                                            (entity, index) => (
                                              <div key={index}>
                                              {!listItem.registeredStatus ? ( 
                                    <Button
                                      className="pointer btn1"
                                      onClick={(e) =>
                                        this.handleLinkBox(listItem.id,listItem.registeredStatus)}
                                    >
                                      Manually Finalize
                                    </Button>
                                   ):""}
                                   </div>
                                 )
                               )}
                               </Box>
                                  <Box
                                    className="OwlCarousel-box  OwlCarousel-padding"
                                    pr={2}
                                    my={"auto"}
                                  >
                                    {listItem.array && listItem.array.length ? (
                                      <OwlCarousel
                                        className="owl-theme owl-tab-theme"
                                        key={`carousel_${listItem.array.length}${index}`}
                                        {...this.state.owlCarouselOptions}
                                      >
                                        {listItem.array
                                          .sort((a, b) =>
                                            a.masterCategories.categoryValue >
                                            b.masterCategories.categoryValue
                                              ? 1
                                              : -1
                                          )
                                          .map((el, index) => {
                                            const {
                                              parent,
                                              masterCategories,
                                              value,
                                              children,
                                              categoryId,
                                            } = el;
                                            if (
                                              (totalCategoryIds[
                                                this.state.categoryId
                                              ]?.subCategory?.includes(
                                                categoryId
                                              ) ||
                                                totalCategoryIds[
                                                  this.state.categoryId
                                                ]?.childCategory?.includes(
                                                  categoryId
                                                )) &&
                                              value != ""
                                            ) {
                                              if (
                                                !availableCatUser.includes(
                                                  listItem.id
                                                )
                                              ) {
                                                availableCatUser.push(
                                                  listItem.id
                                                );
                                              }
                                              return (
                                                <Box
                                                  className="item menu-item"
                                                  key={index}
                                                  border={1}
                                                >
                                                  <p className="category-type">
                                                    {
                                                      masterCategories.categoryValue
                                                    }
                                                  </p>
                                                  {value && value != "" ? (
                                                    <p className="amount-value">
                                                      {value && value != ""
                                                        ? "$" + value
                                                        : "$0"}
                                                    </p>
                                                  ) : null}
                                                </Box>
                                              );
                                            }
                                          })}
                                      </OwlCarousel>
                                    ) : null}
                                  </Box>
                                  {listItem.patientBundle.length > 1 ? (
                                    <Box pr={1} className="pointer">
                                      <Link
                                        to={`${basePath}${
                                          dpBaseRoutes.bundlePatient.path
                                        }?pid=${btoa(
                                          btoa(listItem.id)
                                        )}&cid=${btoa(
                                          btoa(this.state.categoryId)
                                        )}&mode=${btoa(
                                          btoa(this.state.multiPatientMode)
                                        )}`}
                                        color="textSecondary"
                                        className="link-a bundle-info"
                                        underline="none"
                                      >
                                        Bundle:&nbsp;
                                        {listItem.patientBundle.length}
                                        &nbsp;Patient
                                      </Link>
                                    </Box>
                                  ) : null}
                                </Box>
                              </div>
                            </Box>
                          ))
                          
                      ) : (
                        <div className="no-found-text">Not Found</div>
                      )}
                    </InfiniteScroll>
                  </Scrollbars>
                </div>
              </Box>
            ) : notFound ? (
              <Box
                pr={3}
                pl={2}
                width={1}
                display="flex"
                justifyContent="center"
              >
                <div className="no-found-text">Provider Not Found</div>
              </Box>
            ) : (
              <Box
                pr={3}
                pl={2}
                width={1}
                display="flex"
                justifyContent="center"
              >
                <CircularProgress size={24} className="CircularProgress-CSS " />
              </Box>
            )}
          </Box>
        </Box>
      </div>
    );
  }
}

export const FindProviderTabPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(FindProviderTabPanelClass);
export default FindProviderTabPanel;
