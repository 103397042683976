import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  Button,
  TablePagination,
  FormGroup,
} from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Select,
  DialogTitle,
  IconButton,
  TextField,
  MenuItem,
  InputLabel,
  Slide,
  Box,
  FormControl,
  Checkbox,
  Chip,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import Icon from "@material-ui/core/Icon";
import { EMAIL_REGEX, ONLY_AlPHABETS_REGEX } from "__helpers/constants";
import CloseIcon from "@material-ui/icons/Close";

import { FormErrors } from "../../components/Login/FormErrors";
import { userService } from "_services/user.service";
import enMsg from "__helpers/locale/en/en";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import ConfirmModal from "components/Modal/modal";

import { apiPath } from "api";
import { generalAuthTokenHeader } from "__helpers/auth-header";
import {
  PER_PAGE_OPTIONS,
  DEFAULT_PER_PAGE_RECORDS,
  ONLY_NUMBER_REGEX,
} from "__helpers/constants";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import SelectStatus from "components/SelectStatus/selectStatus";
import DialogAction from "components/DialogActions/DialogAction";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import CircularProgress from "@material-ui/core/CircularProgress";
import { MAX_INPUT_LENGTH_RATE } from "__helpers/constants";
import { connect } from "react-redux";
import { reduxLoad } from "js/actions/index";
import ProviderPriofileCategory from "./ProviderPriofileCategory";
import { formatFileSize } from "__helpers/util";
import baseRoutes from "base-routes";
import { basePath } from "base-routes";
import { formatDate } from "date-fns";
import { formatDateToLocalFormat } from "__helpers/util";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Provider ID",
  },
  {
    id: "providerName",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Provider Name",
  },
  {
    id: "user.firstName",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Name",
  },
  // { id: 'address', numeric: false, disablePadding: false, sorting: true, label: 'Address' },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "Phone",
  },
  {
    id: "masterStatusBarEntity.statusName",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Status",
  },
  {
    id: "registeredStatus",
    numeric: false,
    disablePadding: false,  
    sorting: true,
    label: "Type",
  },
  {
    id: "totalDataUsagesKb",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Total Data Usages",
  },
  {
    id: "lastMonthDataUsagesKb",
    numeric: false,
    disablePadding: false,
    sorting: true,
    label: "Last Month Usages",
  },
  {
    id: "user.restDate",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "Created Date",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    sorting: false,
    label: "Action",
    className: "text-wrap",
  },
];
function mapDispatchToProps(dispatch) {
  return {
    reduxLoad: (reduxLoadVal) => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = (state) => {
  return {
    userInfoRedux: state.userInfo,
    categoryListRedux: state.categoryList,
    insuranceListRedux: state.insuranceList,
    patientStatusListRedux: state.patientStatusList,
    hospitalListRedux: state.hospitalList,
    timestampRedux: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
    providerStatusList: state.providerStatusList,
  };
};
class ProviderIndexClass extends Component {
  constructor(props) {
    super(props);
    let spinner = document.getElementById("loadingSpinner");
    this.state = {
      loading: false,
      modalStatus: false,
      facHosModal: false,
      confirmModalStatus: false,
      providerName: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      facility: "",
      status: true,
      registeredStatus:true,
      registeredStatusRadioValid:true,
      checked: false,
      confirmModalMessage: "",
      dataId: "",
      providers: [],
      facilityList: [],
      hospitalList: [],
      providerFacilityData: [],
      providerHospitalData: [],
      hospitalListRedux: this.props.hospitalListRedux
        ? this.props.hospitalListRedux
        : [],
      spinner: spinner,
      perPage: DEFAULT_PER_PAGE_RECORDS,
      total: "",
      currentPage: 0,
      orderBy: "id",
      isDelete: false,
      isView: false,
      facilities: [],
      facilityName: "",
      searchKeyword: "",
      statusFilter: "",
      registerFilter: "",
      subscriptionFee: "",
      globalSubscriptionFee: "",
      enableCareHome: false,
      disableCategories: false,
      providerStatusList: this.props.providerStatusList,
      providerStatusId: "",
      enableSubscription: true,
      formErrors: {
        providerName: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        facility: "",
        status: "",
        facilityList: "",
        hospitalList: "",
        freeMbAllowance: "",
      },
      providerNameValid: false,
      firstNameValid: false,
      lastNameValid: false,
      emailValid: false,
      phoneNumberValid: false,
      facilityValid: true,
      hospitalListValid: true,
      facilityListValid: true,
      freeMbAllowanceValid: true,
      reduxLoadFlag: false,
      subscriptionData: [],
      providerSelectedCategories: [],
      profileCategory: false,
      selectedProviderProfileId: null,
      subscriptionType: [
        { id: "MONTH", value: "Monthly" },
        { id: "PAY_AS_YOU_GO", value: "Pay As You Go" },
      ],
      selectSubscriptionType: "",
      modalMbAllowanceStatus: false,
      dataAllowance: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.search = this.search.bind(this);
    this.statusFilterEvent = this.statusFilterEvent.bind(this);
    this.registerFilterEvent = this.registerFilterEvent.bind(this);
    this.confirmModalSubmit = this.confirmModalSubmit.bind(this);
    this.fetchProviders = this.fetchProviders.bind(this);
    this.handleSubmitFacHos = this.handleSubmitFacHos.bind(this);
    this.modalFacHosOpen = this.modalFacHosOpen.bind(this);
    this.providerProfileCategory = this.providerProfileCategory.bind(this);
    this.modalMbAllowanceOpen = this.modalMbAllowanceOpen.bind(this);
  }
  componentDidUpdate() {
    if (
      this.props.reduxLoadFlag != undefined &&
      this.state.reduxLoadFlag != this.props.reduxLoadFlag
    ) {
      let categoryList = [];
      let insuranceList = [];
      let hospitalList = [];
      let patientStatusList = [];
      let userInfo = {};
      let providerStatusList = {};
      if (this.props.categoryListRedux) {
        let selectedcategoryList = this.props.categoryListRedux;
        categoryList = selectedcategoryList ? selectedcategoryList : [];
      }
      if (this.props.insuranceListRedux) {
        let selectedinsuranceList = this.props.insuranceListRedux;
        insuranceList = selectedinsuranceList ? selectedinsuranceList : [];
      }
      if (this.props.hospitalListRedux) {
        let selectedhospitalList = this.props.hospitalListRedux;
        hospitalList = selectedhospitalList ? selectedhospitalList : [];
      }
      if (this.props.patientStatusListRedux) {
        let selectedpatientStatusList = this.props.patientStatusListRedux;
        patientStatusList = selectedpatientStatusList
          ? selectedpatientStatusList
          : [];
      }
      if (this.props.userInfoRedux) {
        let userInfoRedux = this.props.userInfoRedux;
        userInfo = userInfoRedux ? userInfoRedux : {};
      }
      if (this.props.providerStatusList) {
        providerStatusList = this.props.providerStatusList;
        providerStatusList = providerStatusList ? providerStatusList : {};
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        hospitalListRedux: hospitalList,
        providerStatusList: providerStatusList,
      });
    }
  }
  handleSubmit(event) {
    event.preventDefault();
    if(!this.state.registeredStatusRadioValid && this.state.email == ""){
      this.state.email="defaultUnregister@maxmrj.com";
    }
    let data = {
      firstName: this.state.firstName,
      middleName: "",
      lastName: this.state.lastName,
      email: this.state.email,
      address: this.state.address,
      phoneCode: "+1",
      phoneNumber: this.state.phoneNumber,
      activated: this.state.status,
      facility_id: this.state.facility,
      imageUrl: "string",
      langKey: "string",
      login: "1",
      providerName: this.state.providerName,
      registeredStatus:this.state.registeredStatus,
    };
    if (this.state.dataId) {
      data.id = this.state.dataId;
      let isFacilityExist = true;
      if (isFacilityExist) {
        this.updateProvider(data, false);
      } else {
        this.validateField("facility", "");
      }
    } else {
      this.createProvider(data);
    }
  }
  async createProvider(data) {
    this.setState({ loading: true });
    let showNotification = {};
    let modalCloseStatus = 1;
    const response = await fetch(apiPath.getProviders, {
      method: "POST",
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Provider",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        } else if (response.ok) {
        } else {
          showNotification = {
            title: "Provider",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        if (data.status == "400" && data.message == "error.idexists") {
          modalCloseStatus = 0;
          showNotification = {
            title: "Provider",
            message: data.title,
            type: "danger",
          };
        } else if (data.id) {
          showNotification = {
            title: "Success",
            message: "Provider created successfully",
            type: "success",
          };
          this.fetchProviders();
        }
        return true;
      })
      .catch((error) => {
        showNotification = {
          title: "Provider",
          message: enMsg.clientSideError,
          type: "danger",
        };
      });
    userService.showNotification(showNotification);
    if (modalCloseStatus) {
      this.modalClose();
    }
    this.setState({ loading: false });
  }
  async updateProvider(data, isOnlyStatusChange) {
    this.setState({ loading: true });
    let showNotification = {};
    let modalCloseStatus = 1;
    const response = await fetch(apiPath.getProviders, {
      method: "PUT",
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Provider",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        } else if (response.ok) {
        } else {
          showNotification = {
            title: "Provider",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        }

        return response.json();
      })
      .then((data) => {
        if (data.status == "400" && data.message == "error.idexists") {
          modalCloseStatus = 0;
          showNotification = {
            title: "Provider",
            message: data.title,
            type: "danger",
          };
        } else if (data.id) {
          showNotification = {
            title: "Success",
            message: isOnlyStatusChange
              ? "Status updated successfully"
              : "Provider updated successfully",
            type: "success",
          };
          this.fetchProviders(this.state.searchKeyword);
          // return true;
        }
      })
      .catch((error) => {
        showNotification = {
          title: "Provider",
          message: enMsg.clientSideError,
          type: "danger",
        };
      });
    userService.showNotification(showNotification);
    if (modalCloseStatus) {
      this.modalClose();
    }
    this.setState({ loading: false });
  }
  async handleSubmitFacHos(event) {
    event.preventDefault();
    let providerCatAmountArray = [];
    this.state.subscriptionData.map((data, key) => {
      let catStatus = false;
      let amount = data.amount;
      let cat = data.catSubscriptionType;

      if (this.state.providerSelectedCategories.includes(data.categoryId)) {
        catStatus = true;
        if (this.state[data.categoryId]) {
          amount = parseFloat(this.state[data.categoryId]);
          cat = this.state[`selectSubscription-${data.categoryId}`];
        } else {
          // this.validateCategoryPrice(data.categoryId);
        }
      } else if (this.state.enableCareHome) {
        amount = 0;
      }
      let providerCatAmount = {};
      providerCatAmount["amount"] = amount;
      providerCatAmount["catStatus"] = catStatus;
      providerCatAmount["categoryId"] = data.categoryId;
      providerCatAmount["catSubscriptionType"] = cat;
      providerCatAmountArray.push(providerCatAmount);
    });

    let data = {
      id: this.state.dataId,
      facilityIds: this.state.facilityList,
      hospitalIds: this.state.hospitalList,
      providerCatAmountDto: providerCatAmountArray,
      subscriptionFee:
        this.state.subscriptionFee && this.state.enableSubscription
          ? this.state.subscriptionFee
          : 0,
      homeCareStatus: this.state.enableCareHome,
      statusBarId: this.state.providerStatusId,
      freeMbAllowance: this.state.freeMbAllowance,
    };

    this.setState({ loading: true });
    let showNotification = {};
    const response = await fetch(apiPath.providerFacilityHospital, {
      method: "POST",
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Provider",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        } else if (response.ok) {
        } else {
          showNotification = {
            title: "Provider",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        }

        return response.json();
      })
      .then((data) => {
        if (data.status == "400" && data.title) {
          showNotification = {
            title: "Provider",
            message: data.title,
            type: "dangerLong",
          };
        } else if (data.id) {
          showNotification = {
            title: "Success",
            message: "Provider data saved",
            type: "success",
          };
          this.fetchProviders(this.state.searchKeyword);
          this.modalClose();
          // return true;
        }
      })
      .catch((error) => {
        showNotification = {
          title: "Provider",
          message: enMsg.clientSideError,
          type: "danger",
        };
      });
    userService.showNotification(showNotification);
    this.setState({ loading: false });
  }
  validateCategoryPrice = (categoryId) => {
    if (this.state.providerSelectedCategories.includes(parseInt(categoryId))) {
      let fieldValidationErrors = this.state.formErrors;
      fieldValidationErrors[categoryId] = "cannot be empty";

      this.setState({
        formErrors: fieldValidationErrors,
        [categoryId + "_valid"]: false,
      });
    }
  };
  modalFacHosOpen = (dataId, isView) => {
    if (!isNaN(dataId)) {
      this.state.providers.map((provider) => {
        if (provider.id == dataId) {
          let providerFacilityData = [];
          let providerHospitalData = [];
          let providerCategories = [];
          providerFacilityData = provider.providerFacilityData.map((fList) => {
            return fList.facilityId;
          });
          providerHospitalData = provider.providerHospitalData.map((hList) => {
            return hList.previousHospitalId;
          });

          provider.providerSubscriptionAmountEntity.map((cList) => {
            if (cList.catStatus) {
              providerCategories.push(cList.masterCategories.id);
            }

            this.setState({
              [cList.masterCategories.id]: cList.amount,
            });
            this.setState({
              [`selectSubscription-${cList.masterCategories.id}`]: cList.catSubscriptionType
                ? cList.catSubscriptionType
                : "MONTH",
            });
            this.setState({
              [`selectSubscriptionLabel-${cList.masterCategories.id}`]:
                cList.catSubscriptionType == "MONTH" ? "Per Month" : "Per MB",
            });
          });
          const modifiedProviderSubscriptionAmountEntity = provider.providerSubscriptionAmountEntity.map(
            (cList) => {
              return {
                ...cList,
                catSubscriptionType: "MONTH",
              };
            }
          );
          this.setState({
            dataId: dataId,
            facilityList: providerFacilityData,
            hospitalList: providerHospitalData,
            facHosModal: true,
            //subscriptionData: provider.providerSubscriptionAmountEntity,
            subscriptionData: modifiedProviderSubscriptionAmountEntity,
            providerSelectedCategories: providerCategories,
            subscriptionFee: provider.subscriptionFee,
            globalSubscriptionFee: provider.subscriptionFee,
            enableCareHome: provider.homeCare,
            providerStatusId: provider.statusBar,
            enableSubscription: provider.subscriptionFee == 0 ? false : true,
            freeMbAllowance: provider.freeMbAllowance
              ? provider.freeMbAllowance
              : 10,
          });
        }
      });
    } else {
      let showNotification = {
        title: "Provider",
        message: "Provider data not available",
        type: "danger",
      };
      userService.showNotification(showNotification);
    }
  };
  modalOpen = (dataId, isView) => {
    if (!isNaN(dataId)) {
      this.state.providers.map((provider) => {
        if (provider.id == dataId) {
          this.setState({
            // facility: provider.facility.facilityId,
            address: provider.user.address,
            firstName: provider.user.firstName,
            lastName: provider.user.lastName,
            phoneNumber: provider.user.phoneNumber,
            status: provider.statusBar,
            email: provider.user.email,
            providerName: provider.providerName,
            registeredStatus: provider.registeredStatus,
            providerFacilityData: provider.providerFacilityData,
            providerHospitalData: provider.providerHospitalData,
            // facilityName: provider.facility.facilityName,
            dataId: dataId,
            firstNameValid: true,
            lastNameValid: true,
            emailValid: true,
            phoneNumberValid: true,
            facilityValid: true,
            providerNameValid: true,
          });
        }
      });
    } else {
      this.setState({
        facility: "",
        address: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        status: true,
        dataId: "",
        providerName: "",
        registeredStatus:true,
        firstNameValid: false,
        lastNameValid: false,
        emailValid: false,
        phoneNumberValid: false,
        facilityValid: true,
        providerNameValid: false,
      });
    }
    this.setState({
      modalStatus: true,
      isView: isView,
    });
  };
  modalClose = () => {
    this.setState({
      modalStatus: false,
      facHosModal: false,
      modalMbAllowanceStatus: false,
    });
  };

  confirmModalOpen = (message, dataId, isDelete) => {
    this.setState({
      dataId: dataId,
      confirmModalMessage: message,
      confirmModalStatus: true,
      isDelete: isDelete,
    });
  };
  confirmModalClose = () => {
    this.setState({
      confirmModalStatus: false,
    });
  };
  modalMbAllowanceOpen = async (dataId, isView) => {
    if (!isNaN(dataId)) {
      await Promise.all(
        this.state.providers.map(async (provider) => {
          if (provider.id == dataId) {
            let dataUses = await userService.getProviderDataUsage(provider.id);
            this.state.dataAllowance = dataUses;
            setTimeout(
              function() {
                //this.props.reduxLoad(true);
              }.bind(this),
              500
            );
            this.setState({
              dataAllowance: dataUses,
            });
          }
        })
      );
    } else {
      this.setState({
        dataAllowance: "",
      });
    }
    this.setState({
      modalMbAllowanceStatus: true,
      isView: isView,
    });
  };
  async confirmModalSubmit() {
    if (!this.state.isDelete) {
      Promise.all(
        this.state.providers.map((provider) => {
          if (provider.id == this.state.dataId) {
            const data = {
              firstName: provider.user.firstName,
              middleName: provider.user.middleName,
              lastName: provider.user.lastName,
              email: provider.user.email,
              address: provider.user.address,
              phoneCode: "+1",
              phoneNumber: provider.user.phoneNumber,
              activated: !provider.user.activated,
              facility_id: provider.facility.facilityId,
              providerName: provider.providerName,
              imageUrl: "string",
              langKey: "string",
              login: "1",
              id: this.state.dataId,
            };
            this.updateProvider(data, true);
            this.confirmModalClose();
          }
        })
      );
    } else {
      this.setState({ loading: true });
      let showNotification = {};
      const data = {
        id: this.state.dataId,
      };
      const response = await fetch(
        apiPath.getProviders + "/" + this.state.dataId,
        {
          method: "DELETE",
          headers: generalAuthTokenHeader(),
          body: JSON.stringify(data),
          data: JSON.stringify(data),
        }
      ).then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Provider",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        } else if (response.status === 204) {
          showNotification = {
            title: "Provider",
            message: "Provider deleted successfully",
            type: "success",
          };
          this.fetchProviders();
        } else if (response.ok) {
          showNotification = {
            title: "Provider",
            message: "Provider deleted successfully",
            type: "success",
          };
          this.fetchProviders();
        } else {
          showNotification = {
            title: "Provider",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        }
        userService.showNotification(showNotification);
        this.confirmModalClose();
      });
      this.setState({ loading: false });
    }
  }
  handleChangeInput = (event, id = "") => {
    const name = event.target.name;
    let value = event.target.value;
    console.log("xxxx-", event, id);

    if (value == "PAY_AS_YOU_GO") {
      this.setState({ [id]: 2.35 });
      this.setState({
        [`selectSubscriptionLabel-${id}`]: "Per MB",
      });
    } else if (value == "MONTH") {
      this.setState({ [id]: 100 });
      this.setState({
        [`selectSubscriptionLabel-${id}`]: "Per Month",
      });
    }
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };
  handleChangeInputList = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    //debugger;
    let valueLocal = [];
    if (value && typeof value === "object" && name != "freeMbAllowance") {
      valueLocal = value.filter((v, i) => {
        if (v) {
          return v;
        }
      });
    } else {
      valueLocal = value;
    }
    this.setState(
      {
        [name]: valueLocal,
      },
      () => {
        this.validateFieldFacHos(name, valueLocal);
      }
    );
  };
  handleChecked = () => {
    this.setState({ checked: !this.state.checked });
  };

  componentDidMount() {
    this.fetchProviders();
    const response = fetch(apiPath.getActiveFacilities, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
        } else if (response.ok) {
        } else {
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          facilities: data,
        });
      })
      .catch((error) => {
        return response;
      });
  }

  async fetchProviders(searchKeyword) {
    let apiUrl = "";
    if (searchKeyword) {
      apiUrl =
        apiPath.getProvidersFilter +
        "?page=" +
        this.state.currentPage +
        "&size=" +
        this.state.perPage +
        "&sort=" +
        this.state.orderBy +
        "," +
        (this.state.direction ? "asc" : "desc") +
        "&search=" +
        searchKeyword;
    } else {
      apiUrl =
        apiPath.getProviders +
        "?page=" +
        this.state.currentPage +
        "&size=" +
        this.state.perPage +
        "&sort=" +
        this.state.orderBy +
        "," +
        (this.state.direction ? "asc" : "desc") +
        "";
    }
    this.state.spinner.removeAttribute("hidden", "true");
    let showNotification = {};
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Provider",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        } else if (response.ok) {
          this.setState({
            total: response.headers.get("X-Total-Count"),
          });
        } else if (response.status === 401) {
          userService.logout();
          //window.location.reload(true);
          window.location.replace(baseRoutes.login.path);
        } else {
          showNotification = {
            title: "Provider",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          providers: data,
        });
      })
      .catch((error) => {
        showNotification = {
          title: "Provider",
          message: enMsg.clientSideError,
          type: "danger",
        };
        return response;
      });

    userService.showNotification(showNotification);
    this.state.spinner.setAttribute("hidden", "true");
  }


  async fetchProvidersNewSearch(searchKeyword,statusFilter,registerFilter) {
    let apiUrl = "";
    if (searchKeyword) {
      apiUrl =
        apiPath.getProvidersFilter +
        "?page=" +
        this.state.currentPage +
        "&size=" +
        this.state.perPage +
        "&sort=" +
        this.state.orderBy +
        "," +
        (this.state.direction ? "asc" : "desc") +
        "&search=" +
        searchKeyword;
    }else if(searchKeyword && statusFilter){
      apiUrl =
      apiPath.getProvidersFilter +
      "?page=" +
      this.state.currentPage +
      "&size=" +
      this.state.perPage +
      "&sort=" +
      this.state.orderBy +
      "," +
      (this.state.direction ? "asc" : "desc") +
      "&search=" +
      searchKeyword+ "&status=" +statusFilter;
    }else if(searchKeyword && registerFilter){
      apiUrl =
      apiPath.getProvidersFilter +
      "?page=" +
      this.state.currentPage +
      "&size=" +
      this.state.perPage +
      "&sort=" +
      this.state.orderBy +
      "," +
      (this.state.direction ? "asc" : "desc") +
      "&search=" +
      searchKeyword+ "&registerStatus=" +registerFilter;
    } else if(searchKeyword && statusFilter && registerFilter){
      apiUrl =
      apiPath.getProvidersFilter +
      "?page=" +
      this.state.currentPage +
      "&size=" +
      this.state.perPage +
      "&sort=" +
      this.state.orderBy +
      "," +
      (this.state.direction ? "asc" : "desc") +
      "&search=" +
      searchKeyword+ "&registerStatus=" +registerFilter+"&status=" +statusFilter;
    }else {
      apiUrl =
        apiPath.getProviders +
        "?page=" +
        this.state.currentPage +
        "&size=" +
        this.state.perPage +
        "&sort=" +
        this.state.orderBy +
        "," +
        (this.state.direction ? "asc" : "desc") +
        "";
    }
    this.state.spinner.removeAttribute("hidden", "true");
    let showNotification = {};
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Provider",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        } else if (response.ok) {
          this.setState({
            total: response.headers.get("X-Total-Count"),
          });
        } else if (response.status === 401) {
          userService.logout();
          //window.location.reload(true);
          window.location.replace(baseRoutes.login.path);
        } else {
          showNotification = {
            title: "Provider",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          providers: data,
        });
      })
      .catch((error) => {
        showNotification = {
          title: "Provider",
          message: enMsg.clientSideError,
          type: "danger",
        };
        return response;
      });

    userService.showNotification(showNotification);
    this.state.spinner.setAttribute("hidden", "true");
  }

  async fetchProvidersFilter(status,registeredStatus,searchKeyword) {
    let apiUrl = "";
    //alert("fetchProvidersFilter");
    if(searchKeyword && status && registeredStatus){
      apiUrl =
      apiPath.getProvidersFilter +
      "?page=" +
      this.state.currentPage +
      "&size=" +
      this.state.perPage +
      "&sort=" +
      this.state.orderBy +
      "," +
      (this.state.direction ? "asc" : "desc") +
      "&search=" +
      searchKeyword+ "&registerStatus=" +registeredStatus+"&status=" +status;
    }
    else if (status && registeredStatus) {
      //alert("both");
        apiUrl =
      apiPath.getProvidersFilter +
      "?page=" +
      this.state.currentPage +
      "&size=" +
      this.state.perPage +
      "&sort=" +
      this.state.orderBy +
      "," +
      (this.state.direction ? "asc" : "desc") +
      "&registerStatus=" +
      registeredStatus+"&status=" +
      status;
    } else if(searchKeyword && registeredStatus){
      apiUrl =
      apiPath.getProvidersFilter +
      "?page=" +
      this.state.currentPage +
      "&size=" +
      this.state.perPage +
      "&sort=" +
      this.state.orderBy +
      "," +
      (this.state.direction ? "asc" : "desc") +
      "&search=" +
      searchKeyword+ "&registerStatus=" +registeredStatus;
    }else if(searchKeyword && status){
      apiUrl =
      apiPath.getProvidersFilter +
      "?page=" +
      this.state.currentPage +
      "&size=" +
      this.state.perPage +
      "&sort=" +
      this.state.orderBy +
      "," +
      (this.state.direction ? "asc" : "desc") +
      "&search=" +
      searchKeyword+"&status=" +status;
    }else if(status){
      //alert("status");
      apiUrl =
      apiPath.getProvidersFilter +
      "?page=" +
      this.state.currentPage +
      "&size=" +
      this.state.perPage +
      "&sort=" +
      this.state.orderBy +
      "," +
      (this.state.direction ? "asc" : "desc") +
      "&status=" +
      status;

    }else if(registeredStatus){
      //alert("registeredStatus");
      apiUrl =
        apiPath.getProvidersFilter +
        "?page=" +
        this.state.currentPage +
        "&size=" +
        this.state.perPage +
        "&sort=" +
        this.state.orderBy +
        "," +
        (this.state.direction ? "asc" : "desc") +
        "&registerStatus=" +
        registeredStatus;
    }else if(searchKeyword){
      apiUrl =
      apiPath.getProvidersFilter +
      "?page=" +
      this.state.currentPage +
      "&size=" +
      this.state.perPage +
      "&sort=" +
      this.state.orderBy +
      "," +
      (this.state.direction ? "asc" : "desc") +
      "&search=" +
      searchKeyword;
      
    }else {
      //alert("else");
      apiUrl =
        apiPath.getProvidersFilter +
        "?page=" +
        this.state.currentPage +
        "&size=" +
        this.state.perPage +
        "&sort=" +
        this.state.orderBy +
        "," +
        (this.state.direction ? "asc" : "desc") +
        "";
    }
    this.state.spinner.removeAttribute("hidden", "true");
    let showNotification = {};
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Provider",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        } else if (response.ok) {
          this.setState({
            total: response.headers.get("X-Total-Count"),
          });
        } else if (response.status === 401) {
          userService.logout();
          //window.location.reload(true);
          window.location.replace(baseRoutes.login.path);
        } else {
          showNotification = {
            title: "Provider",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          providers: data,
        });
      })
      .catch((error) => {
        showNotification = {
          title: "Provider",
          message: enMsg.clientSideError,
          type: "danger",
        };
        return response;
      });

    userService.showNotification(showNotification);
    this.state.spinner.setAttribute("hidden", "true");
  }

  validateField = (fieldName, fieldValue) => {
    let fieldValidationErrors = this.state.formErrors;
    let providerNameValid = this.state.providerNameValid;
    let firstNameValid = this.state.firstNameValid;
    let lastNameValid = this.state.lastNameValid;
    let emailValid = this.state.emailValid;
    let phoneNumberValid = this.state.phoneNumberValid;
    let facilityValid = this.state.facilityValid;
    switch (fieldName) {
      case "providerName":
        let pNameErrMsg = "";
        if (fieldValue.trim().length == 0) {
          providerNameValid = false;
          pNameErrMsg = enMsg.providerNameRequiredMsg;
        } else if (fieldValue.trim().length > 100) {
          providerNameValid = false;
          pNameErrMsg = "Provider name cannot exceed 100 characters.";
        } else {
          providerNameValid = true;
          pNameErrMsg = "";
        }
        fieldValidationErrors.providerName = providerNameValid
          ? ""
          : pNameErrMsg;
        break;
      case "firstName":
        if (fieldValue.trim().length == 0) {
          firstNameValid = false;
          fieldValidationErrors.firstName = enMsg.firstNameRequiredMsg;
        } else if (!fieldValue.trim().match(ONLY_AlPHABETS_REGEX)) {
          firstNameValid = false;
          fieldValidationErrors.firstName = "Only alphabets are allowed";
        } else {
          firstNameValid = true;
          fieldValidationErrors.firstName = "";
        }
        break;
      case "lastName":
        if (fieldValue.trim().length == 0) {
          lastNameValid = false;
          fieldValidationErrors.lastName = enMsg.lastNameRequiredMsg;
        } else if (!fieldValue.trim().match(ONLY_AlPHABETS_REGEX)) {
          lastNameValid = false;
          fieldValidationErrors.lastName = "Only alphabets are allowed";
        } else {
          lastNameValid = true;
          fieldValidationErrors.lastName = "";
        }
        break;
      case "email":
        if (fieldValue.trim().length == 0) {
          if(this.state.registeredStatusRadioValid){
            emailValid = false;
            fieldValidationErrors.email = enMsg.emailRequiredMsg;
          }else{
            emailValid = true;
          }
        } else if (!fieldValue.trim().match(EMAIL_REGEX)) {
          emailValid = false;
          fieldValidationErrors.email = enMsg.inValidEmail;
        } else {
          emailValid = true;
          fieldValidationErrors.email = "";
        }
        break;
        case "phoneNumber":
          let errMsg = "";
          const onlyDigits = fieldValue.replace(/[^\d]/g, ""); // Remove all non-digit characters
          if (onlyDigits.length == 0) {
              phoneNumberValid = false;
              errMsg = "Phone number is required.";
          } else if (!/^\(\d{3}\)\d{3}-\d{4}$/.test(fieldValue) && !/^\d{10}$/.test(onlyDigits)) {
              phoneNumberValid = false;
              errMsg = "Invalid number. Use format (111)111-1111 or a 10-digit number.";
          } else if (onlyDigits.length != 10) {
              phoneNumberValid = false;
              errMsg = enMsg.phoneNumberDigit;
          } else {
              phoneNumberValid = true;
          }
          fieldValidationErrors.phoneNumber = phoneNumberValid ? "" : errMsg;
          break;
      case "facility":
        facilityValid = fieldValue > 0;
        fieldValidationErrors.facility = facilityValid
          ? ""
          : enMsg.facilityDropDownRequiredMsg;
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        providerNameValid: providerNameValid,
        firstNameValid: firstNameValid,
        lastNameValid: lastNameValid,
        emailValid: emailValid,
        phoneNumberValid: phoneNumberValid,
        facilityValid: facilityValid,
      },
      this.validateForm
    );
  };
  validateFieldFacHos = (fieldName, fieldValue) => {
    let fieldValidationErrors = this.state.formErrors;
    let hospitalListValid = this.state.hospitalListValid;
    let facilityListValid = this.state.facilityListValid;
    let freeMbAllowanceValid = this.state.freeMbAllowanceValid;
    switch (fieldName) {
      case "facilityListLock":
        facilityListValid = fieldValue.length > 0;
        fieldValidationErrors.facilityList = facilityListValid
          ? ""
          : enMsg.facilityDropDownRequiredMsg;
        break;
      case "hospitalListLock":
        hospitalListValid = fieldValue.length > 0;
        fieldValidationErrors.hospitalList = hospitalListValid
          ? ""
          : enMsg.hospitalDropDownRequiredMsg;
        break;
      case "freeMbAllowance":
        freeMbAllowanceValid = fieldValue.length > 0;
        if (fieldValue.trim().length == 0) {
          freeMbAllowanceValid = false;
          fieldValidationErrors.freeMbAllowance =
            enMsg.freeMbAllowanceRequiredMsg;
        } else if (
          isNaN(fieldValue.trim()) ||
          !ONLY_NUMBER_REGEX.test(fieldValue)
        ) {
          freeMbAllowanceValid = false;
          fieldValidationErrors.freeMbAllowance = enMsg.invalidAllowance;
        } else if (fieldValue.trim() > 1000) {
          freeMbAllowanceValid = false;
          fieldValidationErrors.freeMbAllowance = enMsg.maxAllowance;
        } else if (fieldValue.trim() < 0) {
          freeMbAllowanceValid = false;
          fieldValidationErrors.freeMbAllowance = enMsg.invalidAllowance;
        } else {
          fieldValidationErrors.freeMbAllowance = "";
        }

      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        hospitalListValid: hospitalListValid,
        facilityListValid: facilityListValid,
        freeMbAllowanceValid: freeMbAllowanceValid,
      },
      this.validateFormFacHos
    );
  };

  validateFormFacHos() {
    return (
      this.state.hospitalListValid &&
      this.state.facilityListValid &&
      this.state.freeMbAllowanceValid
    );
  }
  validateForm() {
    if(!this.state.registeredStatusRadioValid){
      this.state.emailValid=true;
    }
    return (
      this.state.providerNameValid &&
      this.state.firstNameValid &&
      this.state.lastNameValid &&
      this.state.emailValid &&
      this.state.phoneNumberValid &&
      this.state.facilityValid
    );
  }

  handleChangePage = (event, newPage) => {
    let statusFilter = this.state.statusFilter;
    let registerFilter= this.state.registerFilter;
    let searchKeyword=this.state.searchKeyword;
    this.setState(
      {
        currentPage: newPage,
      },
      () => this.fetchProvidersFilter(statusFilter,registerFilter,searchKeyword)
    );
  };
  handleChangeRowsPerPage = (event) => {
    let statusFilter = this.state.statusFilter;
    let registerFilter= this.state.registerFilter;
    let searchKeyword=this.state.searchKeyword;
    this.setState(
      {
        perPage: parseInt(event.target.value),
        currentPage: 0,
      },
      () => this.fetchProvidersFilter(statusFilter,registerFilter,searchKeyword)
    );
  };
  tableSortHandler = (property) => (event) => {
    this.setState(
      {
        orderBy: property,
        direction: !this.state.direction,
      },
      () => this.fetchProviders()
    );
  };
  search(event) {
    let searchKeyword = event.target.value.trim();
    let statusFiler=this.state.statusFilter;
    let registerFilter=this.state.registerFilter;
    if (searchKeyword.length > 0) {
      this.setState({ currentPage: 0 }, () =>
        this.fetchProvidersFilter(statusFiler,registerFilter,searchKeyword)
      );
    } else {
      this.fetchProvidersFilter(statusFiler,registerFilter,searchKeyword)
    }
    this.setState({ searchKeyword: searchKeyword });
    return true;
  }
  statusFilterEvent(event) {
    let statusFilter = event.target.value;
    //alert("statusFilterEvent");
    //alert(statusFilter);
    let searchKeyword=this.state.searchKeyword;
    let registerFilter=this.state.registerFilter;
    if (statusFilter.length > 0) {
      this.setState({ currentPage: 0 }, () =>
        this.fetchProvidersFilter(statusFilter,registerFilter,searchKeyword)
      );
    } else {
      this.fetchProviders();
    }
    this.setState({ statusFilter: statusFilter });
    return true;
  }

  registerFilterEvent(event) {
    let statusFilter = this.state.statusFilter;
    let registerFilter= event.target.value;
    let searchKeyword=this.state.searchKeyword;
    //alert(registerFilter);
    if (registerFilter.length > 0) {
      this.setState({ currentPage: 0 }, () =>
        this.fetchProvidersFilter(statusFilter,registerFilter,searchKeyword)
      );
    } else {
      this.fetchProviders();
    }
    this.setState({ registerFilter: registerFilter });
    return true;
  }
  selectProviderCategory = (categoryId) => {
    let providerSelectedCategories = this.state.providerSelectedCategories
      .length
      ? this.state.providerSelectedCategories
      : [];

    if (providerSelectedCategories.includes(categoryId)) {
      const index = providerSelectedCategories.indexOf(categoryId);
      if (index > -1) {
        providerSelectedCategories.splice(index, 1);
      }
    } else {
      providerSelectedCategories.push(categoryId);
    }
    this.setState({
      providerSelectedCategories: providerSelectedCategories,
    });
  };
  enableSubscription = () => {
    this.setState({
      enableSubscription: !this.state.enableSubscription,
    });
  };
  enableCareHome = () => {
    this.setState(
      {
        enableCareHome: !this.state.enableCareHome,
      },
      () => {
        this.setProviderCategory();
      }
    );
  };
  setProviderCategory = () => {
    let providerCategories = [];
    let enableSubscription = this.state.enableSubscription;
    if (!this.state.enableCareHome) {
      this.state.subscriptionData.map((cList) => {
        if (cList.catStatus) {
          providerCategories.push(cList.masterCategories.id);
          this.setState({
            [cList.masterCategories.id]: cList.amount,
          });
        }
      });
      this.setState({
        subscriptionFee: this.state.globalSubscriptionFee,
      });
      enableSubscription = false;
    } else if (this.state.enableCareHome) {
      enableSubscription = true;
      this.state.subscriptionData.map((cList) => {
        this.setState({
          [cList.masterCategories.id]: 0,
          subscriptionFee: 0,
        });
      });
    }
    this.setState({
      providerSelectedCategories: providerCategories.length
        ? providerCategories
        : [],
      disableCategories: this.state.enableCareHome,
      enableSubscription: !this.state.enableCareHome,
    });
  };
  providerProfileCategory = (selectedProviderProfileId, statusFlag) => {
    let profileCategory = this.state.profileCategory ? false : true;
    this.setState({
      profileCategory: profileCategory,
      selectedProviderProfileId: selectedProviderProfileId,
    });
  };
  profile = (dataId) => {
    const url = `${basePath}${baseRoutes.providerUser.path}?id=${dataId}`;
    window.open(url, '_blank');
    // If you need to log dataId or perform other actions, you can do so here
    // console.log(dataId);
  };
  
  registeredStatusBox = (radioFlag) => {
    this.setState({
      registeredStatus: radioFlag,
      registeredStatusRadioValid:radioFlag,
    });
  };


  render() {
    console.log("this.state.providerStatusList",this.state.providerStatusList);
    console.log("this.state.providers",this.state.providers);
    
    return (
      <div id="provider-index">
        <ConfirmModal
          title="Alert"
          message={this.state.confirmModalMessage}
          confirmModalStatus={this.state.confirmModalStatus}
          confirmModalClose={this.confirmModalClose}
          submit={this.confirmModalSubmit}
          loading={this.state.loading}
        />
        <ProviderPriofileCategory
          profileCategory={this.state.profileCategory}
          selectedProviderProfileId={this.state.selectedProviderProfileId}
          providerProfileCategory={this.providerProfileCategory}
        />
        <Dialog
          open={this.state.facHosModal}
          maxWidth={"sm"}
          onClose={this.modalClose}
          aria-labelledby="provider-dialog-title"
          className="add-modal editproviderDialog"
          TransitionComponent={Transition}
        >
          <DialogTitle className="add-modal-title" id="provider-dialog-title">
            {"Edit Provider "}
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.modalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={this.handleSubmitFacHos} noValidate>
            <DialogContent>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                mt={1}
              >
                <Box
                  width={1}
                  // pr={2}
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                >
                  {/* <Box mb={2} width={1}>
                    <InputLabel htmlFor="facility-select-label">
                      Facilities
                    </InputLabel>
                    <Select
                      value={
                        this.state.facilityList ? this.state.facilityList : []
                      }
                      onChange={this.handleChangeInputList}
                      inputProps={{
                        name: "facilityList",
                        // id: 'facility-select-label',
                      }}
                      multiple
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                          },
                        },
                        variant: "menu",
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value="">Select Facilities</MenuItem>
                      {this.state.facilities.length ? (
                        this.state.facilities.map((facility) => (
                          <MenuItem value={facility.facilityId}>
                            {facility.facility_name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value=" ">Not Available</MenuItem>
                      )}
                    </Select>
                    <FormErrors
                      show={!this.state.facilityListValid}
                      formErrors={this.state.formErrors}
                      fieldName="facilityList"
                    />
                  </Box> */}
                  <Box width={1 / 2} pr={2}>
                    <InputLabel htmlFor="facility-select-label">
                      Hospitals
                    </InputLabel>
                    <Select
                      value={
                        this.state.hospitalList ? this.state.hospitalList : []
                      }
                      onChange={this.handleChangeInputList}
                      inputProps={{
                        name: "hospitalList",
                        // id: 'facility-select-label',
                      }}
                      multiple
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                          },
                        },
                        variant: "menu",
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value="">Select Hospitals</MenuItem>
                      {this.state.hospitalListRedux.length ? (
                        this.state.hospitalListRedux.map((hList, key) => (
                          <MenuItem value={hList.previousHospitalId} key={key}>
                            {hList.hospitalName}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value=" ">Not Available</MenuItem>
                      )}
                    </Select>
                    <FormErrors
                      show={!this.state.hospitalListValid}
                      formErrors={this.state.formErrors}
                      fieldName="hospitalList"
                    />
                  </Box>
                  <Box mb={2} width={1 / 2}>
                    <InputLabel htmlFor="facility-select-label">
                      Free Mb Allowance
                    </InputLabel>
                    <TextField
                      InputLabelProps={{ className: "required-label" }}
                      label=""
                      name="freeMbAllowance"
                      value={this.state.freeMbAllowance}
                      onChange={this.handleChangeInputList}
                    />
                    <FormErrors
                      show={!this.state.freeMbAllowanceValid}
                      formErrors={this.state.formErrors}
                      fieldName="freeMbAllowance"
                    />
                  </Box>
                </Box>
                <Box
                  width={1}
                  // pl={2}
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                >
                  <Box width={1} className=" EditProviderModal">
                    <Box
                      width={1}
                      display="flex"
                      flexDirection="row"
                      className="category-price"
                    >
                      <Box
                        width={1 / 3}
                        className="box-category provider-col-1"
                      >
                        <div className="heading">Category</div>
                      </Box>
                      <Box
                        width={1 / 3}
                        className="box-Subscription provider-col-2"
                      >
                        <div className="heading">Subscription</div>
                      </Box>
                      <Box width={1 / 3} className="box-price provider-col-3">
                        <div className="heading">Price ($)</div>
                      </Box>
                    </Box>
                    {/* <Box
                      width={1}
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      className="category-price  front-modal "
                    >
                      <Box width={1 / 3}>
                        <Chip
                          icon={
                            <Checkbox
                              icon={<CircleUnchecked />}
                              checkedIcon={<CircleCheckedFilled />}
                              checked={this.state.enableSubscription}
                            />
                          }
                          label={`Subscription`}
                          onClick={this.enableSubscription}
                          className={
                            this.state.enableSubscription
                              ? "chipinput activeCat"
                              : "chipinput inactiveCat"
                          }
                        />
                      </Box>

                      <Box width={1 / 3}>
                     
                      </Box>
                      <Box width={1 / 3}>
                        <TextField
                          name="subscriptionFee"
                          className="priceinput"
                          value={
                            !this.state.enableSubscription
                              ? 0
                              : this.state.subscriptionFee
                          }
                          label=""
                          onChange={this.handleChangeInput}
                          inputProps={{
                            maxLength: MAX_INPUT_LENGTH_RATE,
                            pattern: "^(d|10).d{2}$",
                          }}
                          pattern="[0-9]{0,5}"
                        />
                      </Box>
                    </Box> */}

                    <Box
                      width={1}
                      display="flex"
                      flexDirection="row"
                      className="category-price front-modal"
                    >
                      <Box
                        width={1}
                        display="flex"
                        justifyContent="flex-start"
                        flexDirection="column"
                      >
                        {typeof this.state.subscriptionData === "object" &&
                        this.state.subscriptionData.length
                          ? this.state.subscriptionData.map((cList, key) => {
                              const {
                                amount,
                                catStatus,
                                masterCategories,
                                catSubscriptionType,
                              } = cList;
                              return (
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  className="category-price-input"
                                >
                                  <Chip
                                    key={masterCategories.id}
                                    icon={
                                      <Checkbox
                                        key={masterCategories.id}
                                        icon={<CircleUnchecked />}
                                        checkedIcon={<CircleCheckedFilled />}
                                        checked={this.state.providerSelectedCategories.includes(
                                          masterCategories.id
                                        )}
                                      />
                                    }
                                    label={masterCategories.categoryValue}
                                    onClick={this.selectProviderCategory.bind(
                                      this,
                                      masterCategories.id
                                    )}
                                    className={
                                      this.state.providerSelectedCategories.includes(
                                        masterCategories.id
                                      )
                                        ? "chipinput activeCat"
                                        : "chipinput inactiveCat"
                                    }
                                  />
                                  <Select
                                    key={"select-" + masterCategories.id}
                                    name={"Subscription-" + masterCategories.id}
                                    value={
                                      this.state[
                                        `selectSubscription-${masterCategories.id}`
                                      ]
                                        ? this.state[
                                            `selectSubscription-${masterCategories.id}`
                                          ]
                                        : "MONTH"
                                    }
                                    labelProps={{ className: "required-label" }}
                                    onChange={(event) =>
                                      this.handleChangeInput(
                                        event,
                                        masterCategories.id
                                      )
                                    }
                                    inputProps={{
                                      name:
                                        "selectSubscription-" +
                                        masterCategories.id,
                                      id: "selectSubscriptionType-select-label",
                                    }}
                                    className={
                                      this.state.providerSelectedCategories.includes(
                                        masterCategories.id
                                      )
                                        ? "chipinput activeCat provider-subscription-select"
                                        : "chipinput inactiveCat provider-subscription-select"
                                    }
                                    displayEmpty
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 48 * 4.5 + 8,
                                          width: 250,
                                        },
                                      },
                                      variant: "menu",
                                      getContentAnchorEl: null,
                                    }}
                                  >
                                    <MenuItem value="">
                                      Select Subscription
                                    </MenuItem>
                                    {this.state.subscriptionType.length ? (
                                      this.state.subscriptionType.map(
                                        (sList, key) => (
                                          <MenuItem value={sList.id} key={key}>
                                            {sList.value}
                                          </MenuItem>
                                        )
                                      )
                                    ) : (
                                      <MenuItem value=" ">
                                        Not Available
                                      </MenuItem>
                                    )}
                                  </Select>
                                  <div className="provider_type">
                                    <TextField
                                      name={"" + masterCategories.id}
                                      className="priceinput"
                                      // value={(this.state.disableCategories && !this.state.providerSelectedCategories.includes(masterCategories.id)) ? 0 : this.state[masterCategories.id]}
                                      value={
                                        !this.state.providerSelectedCategories.includes(
                                          masterCategories.id
                                        ) && this.state.enableCareHome
                                          ? 0
                                          : this.state[masterCategories.id]
                                      }
                                      label=""
                                      onChange={(event) =>
                                        this.handleChangeInput(
                                          event,
                                          masterCategories.id
                                        )
                                      }
                                      inputProps={{
                                        maxLength: MAX_INPUT_LENGTH_RATE,
                                        pattern: "^(d|10).d{2}$",
                                        min: 0,
                                      }}
                                      pattern="[0-9]{0,5}"
                                      // disabled={this.state.disableCategories}
                                    />

                                    <FormErrors
                                      show={
                                        !this.state[
                                          masterCategories.id + "_valid"
                                        ]
                                      }
                                      formErrors={this.state.formErrors}
                                      fieldName={masterCategories.id}
                                    />
                                    <span className="gray_text">
                                      {" "}
                                      {
                                        this.state[
                                          "selectSubscriptionLabel-" +
                                            masterCategories.id
                                        ]
                                      }
                                    </span>
                                  </div>
                                </Box>
                              );
                            })
                          : "No category available..."}
                      </Box>
                    </Box>

                    <Box
                      width={1}
                      display="flex"
                      flexDirection="row"
                      className="category-price front-modal"
                    >
                      <Box width={1 / 2} mb={1} mt={1}>
                        <FormControl>
                          <Select
                            name="providerStatusId"
                            value={this.state.providerStatusId}
                            onChange={this.handleChangeInput}
                          >
                            {this.state.providerStatusList &&
                              this.state.providerStatusList.map(
                                (status, key) => (
                                  <MenuItem key={key} value={status.id}>
                                    {status.statusName}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>

                    <Box
                      width={1}
                      display="flex"
                      flexDirection="row"
                      className="category-price front-modal"
                    >
                      <Box width={1 / 2}>
                        <Chip
                          // key={key}
                          icon={
                            <Checkbox
                              // key={key}
                              icon={<CircleUnchecked />}
                              checkedIcon={<CircleCheckedFilled />}
                              checked={this.state.enableCareHome}
                            />
                          }
                          label={`Care Home`}
                          onClick={this.enableCareHome}
                          className={
                            this.state.enableCareHome
                              ? "chipinput activeCat"
                              : "chipinput inactiveCat"
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions className="modal-actions" justify="center">
              <Button
                type="submit"
                className="btn1"
                disabled={!this.validateFormFacHos() || this.state.loading}
              >
                {this.state.loading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
                Update Provider
              </Button>
              <Button onClick={this.modalClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          open={this.state.modalStatus}
          maxWidth={"sm"}
          onClose={this.modalClose}
          aria-labelledby="provider-dialog-title"
          className="add-modal"
          TransitionComponent={Transition}
        >
          <DialogTitle className="add-modal-title" id="provider-dialog-title">
            {this.state.isView
              ? "View Provider"
              : this.state.dataId
              ? "Edit Provider"
              : "Add Provider"}
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.modalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={this.handleSubmit} noValidate>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", marginTop: "10px" }} className="register-type">
          {/* <span style={{ marginRight: "120px" }}></span> Empty span for space */}
               <Box
                >
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={this.state.registeredStatus}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio color="primary" />}
                      label="Registered"
                      onClick={(event) => this.registeredStatusBox(true)}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio color="primary" />}
                      label="Unregistered"
                      onClick={(event) => this.registeredStatusBox(false)}
                    />
                  </RadioGroup>
                </Box>
           </div>
            {this.state.isView ? (
              <DialogContent>
                <Box display="flex" justifyContent="flex-start" mb={2} mt={1}>
                  <Box pr={1} width={1}>
                    <InputLabel className="view-label">
                      Provider Name
                    </InputLabel>
                    <InputLabel className="view-label value">
                      {this.state.providerName}
                    </InputLabel>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
                  <Box pr={1} width={1 / 2}>
                    <InputLabel className="view-label">
                      Primary Contact First Name
                    </InputLabel>
                    <InputLabel className="view-label value">
                      {this.state.firstName}
                    </InputLabel>
                  </Box>
                  <Box pl={1} width={1 / 2}>
                    <InputLabel className="view-label">
                      Primary Contact Last Name
                    </InputLabel>
                    <InputLabel className="view-label value">
                      {this.state.lastName}
                    </InputLabel>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
                  <Box pr={1} width={1 / 2}>
                    <InputLabel className="view-label">
                      Email Address
                    </InputLabel>
                    <InputLabel className="view-label value">
                      {this.state.email}
                    </InputLabel>
                  </Box>
                  <Box pl={1} width={1 / 2}>
                    <InputLabel className="view-label">Phone Number</InputLabel>
                    <InputLabel className="view-label value">
                      {/* {this.state.phoneNumber
                        ? "(" +
                          this.state.phoneNumber.match(
                            /(\d{3})(\d{3})(\d{4})/
                          )[1] +
                          ") " +
                          this.state.phoneNumber.match(
                            /(\d{3})(\d{3})(\d{4})/
                          )[2] +
                          "-" +
                          this.state.phoneNumber.match(
                            /(\d{3})(\d{3})(\d{4})/
                          )[3]
                        : "N/A"} */}
                         {(() => {
                              const phoneNumber = this.state.phoneNumber;
                              if (phoneNumber) {
                                const onlyDigits = phoneNumber.replace(/[^\d]/g, ""); // Remove all non-digit characters
                                if (onlyDigits.length === 10) {
                                  return `(${onlyDigits.slice(0, 3)}) ${onlyDigits.slice(3, 6)}-${onlyDigits.slice(6)}`;
                                } else {
                                  return " ";
                                }
                              } else {
                                return " ";
                              }
                            })()}
                    </InputLabel>
                   
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
                  <Box pr={1} width={1}>
                    <InputLabel className="view-label">Facility</InputLabel>
                    <InputLabel className="view-label value">
                      {this.state.providerFacilityData &&
                      this.state.providerFacilityData.length
                        ? this.state.providerFacilityData.map((fList, key) => {
                            return (
                              <Chip
                                className={"chipval"}
                                key={key}
                                label={fList.facility.facility_name}
                                style={{ margin: 2 }}
                              />
                            );
                          })
                        : "No facilities added"}
                      {/* {this.state.facilityName} */}
                    </InputLabel>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
                  <Box pl={1} width={1}>
                    <InputLabel className="view-label">Hospital</InputLabel>
                    <InputLabel className="view-label value">
                      {this.state.providerHospitalData &&
                      this.state.providerHospitalData.length
                        ? this.state.providerHospitalData.map((hList, key) => {
                            return (
                              <Chip
                                className={"chipval"}
                                key={key}
                                label={
                                  hList.masterPreviousHospital.hospitalName
                                }
                                style={{ margin: 2 }}
                              />
                            );
                          })
                        : "No hospital added"}
                      {/* {this.state.facilityName} */}
                    </InputLabel>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-start" mb={4} mt={2}>
                  <Box pl={1} width={1 / 2}>
                    <InputLabel className="view-label">Status</InputLabel>
                    <InputLabel className="view-label value">
                      {this.state.providerStatusList &&
                        this.state.providerStatusList.map((status) =>
                          status.id == this.state.status
                            ? status.statusName
                            : ""
                        )}
                    </InputLabel>
                  </Box>
                </Box>
              </DialogContent>
            ) : (
              <div>
                <DialogContent>
                  <Box display="flex" justifyContent="flex-start" mb={0} mt={2}>
                    

                    <Box pr={1} width={1}>
                      <TextField
                        InputLabelProps={{ className: "required-label" }}
                        name="providerName"
                        value={this.state.providerName}
                        onChange={this.handleChangeInput}
                        data-validators="isRequired"
                        label="Provider Name"
                      />
                      <FormErrors
                        show={!this.state.providerNameValid}
                        formErrors={this.state.formErrors}
                        fieldName="providerName"
                      />
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent="flex-start" mb={2}>
                    <Box pr={1} width={1 / 2}>
                      <TextField
                        InputLabelProps={{ className: "required-label" }}
                        label="Primary Contact First Name"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={this.handleChangeInput}
                      />
                      <FormErrors
                        show={!this.state.firstNameValid}
                        formErrors={this.state.formErrors}
                        fieldName="firstName"
                      />
                    </Box>
                    <Box pl={1} width={1 / 2}>
                      <TextField
                        InputLabelProps={{ className: "required-label" }}
                        label="Primary Contact Last Name"
                        name="lastName"
                        value={this.state.lastName}
                        onChange={this.handleChangeInput}
                      />
                      <FormErrors
                        show={!this.state.lastNameValid}
                        formErrors={this.state.formErrors}
                        fieldName="lastName"
                      />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-start" mb={2}>
                    {this.state.registeredStatusRadioValid && ( 
                    <Box pr={1} width={1 / 2}>
                      <TextField
                        InputLabelProps={{ className: "required-label" }}
                        label="Email Address"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChangeInput}
                      />
                      <FormErrors
                        show={!this.state.emailValid}
                        formErrors={this.state.formErrors}
                        fieldName="email"
                      />
                    </Box>
                    )}
                    {!this.state.registeredStatusRadioValid && ( 
                    <Box pr={1} width={1 / 2}>
                      <TextField
                        label="Email Address"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChangeInput}
                      />
                    </Box>
                    )}
                    <Box pl={1} width={1 / 2}>
                      <TextField
                        InputLabelProps={{ className: "required-label" }}
                        label="Phone Number"
                        name="phoneNumber"
                        value={this.state.phoneNumber}
                        onChange={this.handleChangeInput}
                      />
                      <FormErrors
                        show={!this.state.phoneNumberValid}
                        formErrors={this.state.formErrors}
                        fieldName="phoneNumber"
                      />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-start" mb={0}></Box>
                </DialogContent>
                <DialogActions className="modal-actions" justify="center">
                  <Button
                    type="submit"
                    className="btn1"
                    disabled={!this.validateForm() || this.state.loading}
                  >
                    {this.state.loading && (
                      <CircularProgress size={24} className="buttonProgress" />
                    )}
                    {this.state.dataId ? "Update Provider" : "Add Provider"}
                  </Button>
                  <Button onClick={this.modalClose} color="primary">
                    Cancel
                  </Button>
                </DialogActions>
              </div>
            )}
          </form>
        </Dialog>
        <Dialog
          open={this.state.modalMbAllowanceStatus}
          maxWidth={"sm"}
          onClose={this.modalClose}
          aria-labelledby="provider-dialog-title"
          className="add-modal"
          TransitionComponent={Transition}
        >
          <DialogTitle className="add-modal-title" id="provider-dialog-title">
            View Data Usages
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.modalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={this.handleSubmit} noValidate>
            <DialogContent>
              <Box display="flex" justifyContent="flex-start" mb={2} mt={1}>
                <Box pr={1} width={1}>
                  <InputLabel className="view-label">
                    Total Data Usages
                  </InputLabel>
                  <InputLabel className="view-label value">
                    {this.state.dataAllowance.totalDataUsages}
                  </InputLabel>
                </Box>
              </Box>
              {this.state.dataAllowance ? (
                <>
                  <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
                    <Box pr={1} width={1 / 2}>
                      <InputLabel className="view-label">
                        Free Mb Allowance
                      </InputLabel>
                      <InputLabel className="view-label value">
                        {this.state.dataAllowance.freeMbAllowance} MB
                      </InputLabel>
                    </Box>
                    <Box pl={1} width={1 / 2}>
                      <InputLabel className="view-label">
                        Remaining Free Allowance
                      </InputLabel>
                      <InputLabel className="view-label value">
                        {this.state.dataAllowance.remainingFreeAllowance}
                      </InputLabel>
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
                    <Box pr={1} width={1 / 2}>
                      <InputLabel className="view-label">
                        Last Three Month Data Usages
                      </InputLabel>
                      {this.state.dataAllowance &&
                        this.state.dataAllowance.lastThreeMonthDataUsages && (
                          <>
                            {this.state.dataAllowance.lastThreeMonthDataUsages.map(
                              (data, index) => (
                                <>
                                  <InputLabel
                                    key={index}
                                    className="view-label value"
                                  >
                                    <span className="mr-10 new-space">
                                      {data.month}
                                    </span>{" "}
                                    {data.dataUsages}
                                  </InputLabel>
                                </>
                              )
                            )}
                          </>
                        )}
                    </Box>
                    <Box className="pl-8" pr={1} width={1 / 2}>
                      <InputLabel className="view-label">
                        Category Data Usages
                      </InputLabel>
                      {this.state.dataAllowance &&
                        this.state.dataAllowance.categoryTotalDataUsages && (
                          <>
                            {this.state.dataAllowance.categoryTotalDataUsages.map(
                              (data, index) => (
                                <>
                                  <InputLabel
                                    key={index}
                                    className="view-label value"
                                  >
                                    <span className="mr-10 new-space">
                                      {data.category}
                                    </span>{" "}
                                    {data.dataUsages}
                                  </InputLabel>
                                </>
                              )
                            )}
                          </>
                        )}
                    </Box>
                  </Box>
                </>
              ) : (
                <Box display="flex" justifyContent="flex-start" mb={2} mt={2}>
                  No data
                </Box>
              )}
            </DialogContent>
          </form>
        </Dialog>
        <GridContainer>
          <Card>
            <CardHeader color="success" className={"cusCardTitle"}>
              <h4>Providers</h4>
            </CardHeader>
            <GridItem xs={12} sm={12} md={12}>
            <div className="search-btn-cover">
                <input
                  type="text"
                  title="Search by provider name/name/phone."
                  placeholder="Search by provider name/name/phone."
                  className="search-btn"
                  onChange={this.search}
                />
                <i className="material-icons">search</i>
                
              </div>
              <div className="filterby-btn-cover">Filter By :</div> 
            <div className="status-btn-cover"> 
            <Box
              display="flex"
              flexDirection="row"
              width={1}
              className="patient-list-page patient-list-page-new"
            >
            <Box pr={1.5} className="left-content-box">
              <div className="left-content-header">
                <Box
                  display="flex"
                  className="divcontainer"
                  mb={2}
                  ref="dropzone"
                >
                  <Box
                    className="action-box patientListFilters"
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                  >
                   
                    <Box pl={1} pr={1} className="box-filter filter-label">
                      <FormGroup>
                        <FormControl variant="outlined">
                        <InputLabel className="view-label">Status</InputLabel>
                          <Select
                            id="filter"
                            color="secondary"
                            name="filter"
                            className="filter-select"
                            onChange={this.statusFilterEvent}
                          >
                            <MenuItem value="">{"None"}</MenuItem>
                            {this.state.providerStatusList &&
                            this.state.providerStatusList.length
                              ? this.state.providerStatusList.map(
                                  (fList, key) => {
                                    return (
                                      <MenuItem key={key} value={fList.statusName}>
                                        {fList.statusName}
                                      </MenuItem>
                                    );
                                  }
                                )
                              : null}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Box>
                    <Box pl={1} className="box-filter filter-orderby">
                      <FormGroup>
                        <FormControl
                          variant="outlined"
                          className={"mat_select"}
                        >
                          <InputLabel>Type</InputLabel>
                          <Select
                            id="filter"
                            color="secondary"
                            name="filter"
                            className="filter-select"
                            onChange={this.registerFilterEvent}
                          >
                            <MenuItem value="">{"None"}</MenuItem>
                            <MenuItem value="Registered">{"Registered"}</MenuItem>
                            <MenuItem value="Unregistered">{"Unregistered"}</MenuItem>
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Box>
                  </Box>
                </Box>
              </div>
            </Box>
          </Box>
          </div>
              
              <div className="button-cover">
                <button className="pointer btn1" onClick={this.modalOpen}>
                  Add Provider
                </button>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              
            </GridItem>
            <CardBody>
              <div className="tableResponsive">
                <Table>
                  <TableHead>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.numeric ? "right" : "left"}
                          padding={headCell.disablePadding ? "none" : "default"}
                          className={headCell.className}
                        >
                          {headCell.sorting ? (
                            <TableSortLabel
                              active={this.state.orderBy == headCell.id}
                              direction={this.state.direction ? "asc" : "desc"}
                              onClick={this.tableSortHandler(headCell.id)}
                            >
                              {headCell.label}
                            </TableSortLabel>
                          ) : (
                            headCell.label
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.providers.length ? (
                      this.state.providers.map((provider) => (
                        <TableRow key={provider.id}>
                          <TableCell>{provider.user.id}</TableCell>
                          <TableCell>{provider.providerName}</TableCell>
                          <TableCell>
                            {provider.user.firstName} {provider.user.middleName}{" "}
                            {provider.user.lastName}
                          </TableCell>
                         {/*  <TableCell>
                            {provider.providerFacilityData &&
                            provider.providerFacilityData.length
                              ? provider.providerFacilityData.length +
                                " facilities"
                              : "No facility"}
                          </TableCell> */}
                         <TableCell>
                            {(() => {
                              const phoneNumber = provider.user.phoneNumber;
                              if (phoneNumber) {
                                const onlyDigits = phoneNumber.replace(/[^\d]/g, ""); // Remove all non-digit characters
                                if (onlyDigits.length === 10) {
                                  return `(${onlyDigits.slice(0, 3)}) ${onlyDigits.slice(3, 6)}-${onlyDigits.slice(6)}`;
                                } else {
                                  return " ";
                                }
                              } else {
                                return " ";
                              }
                            })()}
                            </TableCell>
 
                          <TableCell>
                            {this.state.providerStatusList &&
                              this.state.providerStatusList.map((status) =>
                                status.id == provider.statusBar
                                  ? status.statusName
                                  : ""
                              )}
                          </TableCell>
                          <TableCell>{(provider.registeredStatus)?"Registered":"Unregistered"}</TableCell>
                          <TableCell>
                            {formatFileSize(provider.totalDataUsagesKb)}
                          </TableCell>
                          <TableCell>
                            {formatFileSize(provider.lastMonthDataUsagesKb)}
                          </TableCell>
                          <TableCell>
                            {formatDateToLocalFormat(provider.user.resetDate)}
                          </TableCell>
                          <TableCell className="text-wrap">
                            <span
                              className="edit-action"
                              onClick={() => this.modalOpen(provider.id, true)}
                            >
                              <Icon className="fa fa-eye" aria-hidden="true" />
                            </span>
                            <span
                              className="edit-action"
                              onClick={() =>
                                this.modalMbAllowanceOpen(provider.id, false)
                              }
                            >
                              <Icon
                                className="fa fa-cloud-download"
                                aria-hidden="true"
                              />
                            </span>
                            <span
                              className="edit-action"
                              onClick={() =>
                                this.modalFacHosOpen(provider.id, false)
                              }
                            >
                              <Icon
                                className="fa fa-pencil"
                                aria-hidden="true"
                              />
                            </span>
                            <span
                              className="edit-action"
                              onClick={() => this.profile(provider.id)}
                            >
                              <Icon className="fa fa-user" aria-hidden="true" />
                            </span>
                            <span
                              className="edit-action"
                              onClick={() => this.modalOpen(provider.id, false)}
                            >
                              <Icon
                                className="fa fa-pencil-square-o"
                                aria-hidden="true"
                              />
                            </span>
                            <span
                              className="edit-action"
                              onClick={() =>
                                this.providerProfileCategory(provider.id, true)
                              }
                            >
                              <i class="fa fa-th-large large_square"></i>
                            </span>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colspan="7">
                          <div className="not-found-text">
                            Providers not found
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={PER_PAGE_OPTIONS}
                  component="div"
                  count={parseInt(this.state.total)}
                  rowsPerPage={this.state.perPage}
                  page={this.state.currentPage}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </div>
            </CardBody>
          </Card>
        </GridContainer>
      </div>
    );
  }
}

export const ProviderIndex = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderIndexClass);
export default ProviderIndex;
